import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'

import vertex from './vertex.glsl'
import fragment from './fragment.glsl'

const InteriorMappingShader = shaderMaterial(
  { multi: 1, divisions: 13 },
  vertex,
  fragment
)

export default InteriorMappingShader;