/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ApartmentBuilding_v2.glb -o ApartmentBuilding_v2.jsx 
*/

import React, { useRef,useEffect } from 'react'
import { useGLTF,  } from '@react-three/drei'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'
export function ApartmentBuilding_v2(props) {
  const { nodes, materials } = useGLTF('assets/Building/ApartmentBuilding_v2.gltf')
  // const { nodes, materials } = useGLTF('assets/Building/ApartmentBuilding_v2.glb')


  useEffect(() => {
    console.log("Apartment Building_v2 UseEffect..");
    //sun polarAngle (lat) to color
    let intensity = Math.sin(props.lat * (Math.PI));
    let blue = new THREE.Color("#0000ff");
    let color = "#" + new THREE.Color(intensity, intensity, intensity).getHexString() ;
    console.log("color: " , color);
    let rIntensity = Math.cos(props.lat * (Math.PI));
    let orange = new THREE.Color("#ff6600");
    let ncolor = new THREE.Color(rIntensity, rIntensity, rIntensity).getHexString() * orange;

    let fcolor = new THREE.Color("#ffffff");
    fcolor = color + ncolor;

    // making the sun orange when going down and blue when going up

    applyProps(materials.cityMat, {vertexColors: true, side: THREE.DoubleSide, color:color})
    applyProps(materials.Glass_, {transparent: true, opacity: intensity, side: THREE.DoubleSide, color:"#555555"})
  },[props.lat]);

  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Apartment_building_1.geometry} material={materials.Color_1} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_2.geometry} material={materials.Concrete_1} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_3.geometry} material={materials.Bricks25} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_4.geometry} material={materials.Tiles_Broken} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_5.geometry} material={materials.Pave_1} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_6.geometry} material={materials.Floor} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_7.geometry} material={materials.Color_2} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_8.geometry} material={materials.Metal} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_9.geometry} material={materials.Grass} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_10.geometry} material={materials.Wood} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_11.geometry} material={materials.Frame} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_13.geometry} material={materials.Ramp} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_14.geometry} material={materials.Concrete_2} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_15.geometry} material={materials.Road} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_16.geometry} material={materials.Pave_2} castShadow={true} receiveShadow={true} />
        <mesh geometry={nodes.Apartment_building_12.geometry} material={materials.Glass_} castShadow={true} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.roomMesh_1.geometry} material={materials.retail_mat_01} />
        <mesh geometry={nodes.roomMesh_2.geometry} material={materials.retail_mat_02} />
        <mesh geometry={nodes.roomMesh_3.geometry} material={materials.retail_mat_03} />
        <mesh geometry={nodes.roomMesh_4.geometry} material={materials.office_01} />
        <mesh geometry={nodes.roomMesh_5.geometry} material={materials.office_02} />
        <mesh geometry={nodes.roomMesh_6.geometry} material={materials.office_03} />
        <mesh geometry={nodes.roomMesh_7.geometry} material={materials.office_04} />
        <mesh geometry={nodes.roomMesh_8.geometry} material={materials.room_01} />
        <mesh geometry={nodes.roomMesh_9.geometry} material={materials.room_02} />
        <mesh geometry={nodes.roomMesh_10.geometry} material={materials.room_03} />
        <mesh geometry={nodes.roomMesh_11.geometry} material={materials.room_04} />
      </group>
      <mesh geometry={nodes.cityMesh.geometry} material={materials.cityMat} rotation={[Math.PI / 2, 0, 0]} castShadow={true} receiveShadow={true}/>
    </group>
  )
}

useGLTF.preload('assets/Building/ApartmentBuilding_v2.gltf')
// useGLTF.preload('assets/Building/ApartmentBuilding_v2.glb')
export default ApartmentBuilding_v2;