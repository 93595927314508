/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Corolla_2017.glb -o Corolla2.js 
*/
import React, { useRef, useState, forwardRef, useImperativeHandle,useEffect } from 'react'

import { useGLTF } from '@react-three/drei'
import { applyProps } from '@react-three/fiber'

// export function Corolla2(props) {
const Corolla2 = forwardRef((props, ref)=> {

    const [childState, setChildState] = useState(props.parentState);
    const [activeColor, setActiveColor] = useState(props.activeColor);
    const [activeRoughness,setActiveRoughness] = useState(props.activeRoughness);
    const [activeMetal,setActiveMetal] = useState(props.activeMetal);
    const [activeGlass1,setActiveGlass1] = useState(props.activeGlass1);
    const [activeGlass2,setActiveGlass2] = useState(props.activeGlass2);
    const [activeGlass3,setActiveGlass3] = useState(props.activeGlass3);
  
    useEffect(() => {
      setActiveColor(props.activeColor);
      applyProps(materials['main_paint.001'], { color: props.activeColor });
    //   applyProps(materials['main_paint.001'], { roughness: props.activeRoughness });
    //   applyProps(materials['main_paint.001'],{metalness: ( props.activeMetal)})
    //   applyProps(materials['main_paint.001'],{metalness: getMetal(props.activeColor)})
    //   applyProps(materials['main_paint.001'],{metalness: (1-getRoughness(props.activeColor)/2)})
    //   applyProps(materials['main_paint.001'], { roughness: getRoughness(props.activeColor) });
      applyProps(materials['main_paint.001'],{metalness: .85})
      applyProps(materials['main_paint.001'], { roughness: 0.15 });
      // set Glass material opacity to activeGlass1
      applyProps(materials.glass_windows, { opacity: props.activeGlass1, color: getGlassColor(props.activeGlass1) });
      applyProps(materials.glass_screen_front, { opacity: props.activeGlass2, color: getGlassColor(props.activeGlass2) });
      applyProps(materials.glass_screen_back, { opacity: props.activeGlass3, color: getGlassColor(props.activeGlass3) });
  
    },[props.activeColor,props.activeRoughness,props.activeMetal, props.activeGlass1,props.activeGlass2,props.activeGlass3]);
    
    // calc glass color based on activeGlassTransparency
    // brightness = 1 - transparency
    function getGlassColor(activeGlassTransparency){
        var glassColor = "#ffffff";
        var brightness =  .5 - activeGlassTransparency;
        var hexBrightness = Math.round(255 * brightness).toString(16);
        glassColor = "#" + hexBrightness + hexBrightness + hexBrightness;
        console.log("Glass Color: " + glassColor);
        return glassColor;
    }
    // create a function which takes hex color(#aabbcc) input, convert to rgb , then calc brightness (value) of color and calculates the roughness based on brightness/luminance factor of color
    function getRoughness(hexColor){
      console.log("Calc Roughness >> HexColor: " + hexColor)
      var r = parseInt(hexColor.slice(1,3),16);
      var g = parseInt(hexColor.slice(3,5),16);
      var b = parseInt(hexColor.slice(5,7),16);
      var v = Math.max(r,g,b);
      var roughness = (v/255);
      // remap roughness to 0.3 - 0.75
      roughness = roughness * 0.4 + 0.1;
      console.log("Roughness: " + roughness);
      return roughness;
    }
    function getMetal(hexColor){
        console.log("HexColor: " + hexColor)
        var r = parseInt(hexColor.slice(1,3),16);
        var g = parseInt(hexColor.slice(3,5),16);
        var b = parseInt(hexColor.slice(5,7),16);
        var v = Math.max(r,g,b);
        var metal = 1 - (v/255);
        // remap roughness to 0.3 - 0.75
        // roughness = roughness * 0.4 + 0.1;
        console.log("Metal: " + metal);
        return metal;
      }
    function click(){
      console.log("I'm a Toyota Corolla, click");
    }

  const { nodes, materials } = useGLTF('assets/coolite/Corolla_2017.glb')

  // set defaults for car body
  const [carBodyColor, setCarBodyColor] = useState('#621D1D')
  const [carBodyMetal, setCarBodyMetal] = useState(1.0)
  const [carBodyRough, setCarBodyRough] = useState(0.2)

  
  
  // meant to be controlled by LEVA UI
  const setCarBodyPaintColor = (color) => {
      setCarBodyColor(color);
      applyProps(materials['main_paint.001'], { color: color , emissiveIntensity: 0});

    }
  const updateShaderRoughness = (rough) => {
    setCarBodyRough(rough);
    applyProps(materials.body, { roughness: rough, emissiveIntensity: 0});
  }
  const updateShaderMetalness = (metal) => {
    setCarBodyMetal(metal);
    applyProps(materials.body, { metal: metal, emissiveIntensity: 0});
  }
  
  return (
    <group {...props} dispose={null}>
      <group ref={ref} position={[0.877, 0.326, -1.464]}>
        <mesh geometry={nodes.Object_270.geometry} material={materials.e180_black} />
        <mesh geometry={nodes.Object_271.geometry} material={materials.e180_red} />
        <mesh geometry={nodes.Object_272.geometry} material={materials.displaye180} />
      </group>
      <group position={[-0.877, 0.326, -1.464]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh geometry={nodes.Object_276.geometry} material={materials.e180_chrome} />
        <mesh geometry={nodes.Object_277.geometry} material={materials.e180_gray} />
      </group>
      <group position={[-0.877, 0.326, -1.464]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh geometry={nodes.Object_279.geometry} material={materials.e180_black} />
        <mesh geometry={nodes.Object_280.geometry} material={materials.e180_red} />
        <mesh geometry={nodes.Object_281.geometry} material={materials.displaye180} />
      </group>
      <group position={[0.877, 0.337, 1.498]}>
        <mesh geometry={nodes.Object_285.geometry} material={materials.e180_chrome} />
        <mesh geometry={nodes.Object_286.geometry} material={materials.e180_gray} />
      </group>
      <group position={[0.877, 0.337, 1.498]}>
        <mesh geometry={nodes.Object_288.geometry} material={materials.e180_black} />
        <mesh geometry={nodes.Object_289.geometry} material={materials.e180_red} />
        <mesh geometry={nodes.Object_290.geometry} material={materials.displaye180} />
      </group>
      <group position={[-0.877, 0.337, 1.498]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh geometry={nodes.Object_294.geometry} material={materials.e180_chrome} />
        <mesh geometry={nodes.Object_295.geometry} material={materials.e180_gray} />
      </group>
      <group position={[-0.877, 0.337, 1.498]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh geometry={nodes.Object_297.geometry} material={materials.e180_black} />
        <mesh geometry={nodes.Object_298.geometry} material={materials.e180_red} />
        <mesh geometry={nodes.Object_299.geometry} material={materials.displaye180} />
      </group>
      <group position={[0.877, 0.326, -1.464]}>
        <mesh geometry={nodes.Object_115.geometry} material={materials.e180_chrome} />
        <mesh geometry={nodes.Object_116.geometry} material={materials.e180_gray} />
      </group>
      <mesh geometry={nodes.Object_15.geometry} material={materials.glass_screen_back} />
      <mesh geometry={nodes.Object_16.geometry} material={materials.glass_screen_back} />
      <mesh geometry={nodes.Object_203.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_205.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_206.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_207.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_208.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_209.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_210.geometry} material={materials.e180_white} />
      <mesh geometry={nodes.Object_212.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_214.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_215.geometry} material={materials.e180_chrome} />
      <group position={[0.362, 0.893, 0.583]} rotation={[-1.226, 0, 0]}>
        <mesh geometry={nodes.Object_257.geometry} material={materials.e180_chrome} />
        <mesh geometry={nodes.Object_258.geometry} material={materials.e180_gray} />
        <mesh geometry={nodes.Object_259.geometry} material={materials.e180_interior} />
      </group>
      <mesh geometry={nodes.Object_217.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_218.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_219.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_220.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_221.geometry} material={materials.e180_long} />
      <mesh geometry={nodes.Object_222.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_224.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_225.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_226.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_227.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_228.geometry} material={materials.e180_short} />
      <mesh geometry={nodes.Object_229.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_230.geometry} material={materials.e180_tail} />
      <mesh geometry={nodes.Object_231.geometry} material={materials.e180_intlight} />
      <mesh geometry={nodes.Object_232.geometry} material={materials.e180_multi} />
      <mesh geometry={nodes.Object_234.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_235.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_236.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_237.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_238.geometry} material={materials.e180_gauge} />
      <mesh geometry={nodes.Object_239.geometry} material={materials.e180_intlight} />
      <mesh geometry={nodes.Object_241.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_242.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_243.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_244.geometry} material={materials.mirror} />
      <mesh geometry={nodes.Object_246.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_247.geometry} material={materials.e180_interior} />
      <mesh geometry={nodes.Object_249.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_250.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_252.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_253.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_254.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_255.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_261.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_262.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_263.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_264.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_266.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_267.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_268.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_95.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_107.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_93.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_91.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_109.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_89.geometry} material={materials.sunburst} position={[0.877, 0.337, 1.498]} />
      <mesh geometry={nodes.Object_87.geometry} material={materials.sunburst} position={[-0.877, 0.337, 1.498]} />
      <mesh geometry={nodes.Object_85.geometry} material={materials.sunburst} position={[0.877, 0.326, -1.464]} />
      <mesh geometry={nodes.Object_83.geometry} material={materials.sunburst} position={[-0.877, 0.326, -1.464]} />
      <mesh geometry={nodes.Object_196.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_197.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_198.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_199.geometry} material={materials.e180_fog} />
      <mesh geometry={nodes.Object_201.geometry} material={materials.e180_glassred} />
      <mesh geometry={nodes.Object_190.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_191.geometry} material={materials.e180_glassred} />
      <mesh geometry={nodes.Object_192.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_193.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_194.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_81.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_146.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_147.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_148.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_149.geometry} material={materials.e180_interior} />
      <mesh geometry={nodes.Object_150.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_159.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_160.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_161.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_162.geometry} material={materials.e180_interior} />
      <mesh geometry={nodes.Object_163.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_165.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_166.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_167.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_168.geometry} material={materials.e180_interior} />
      <mesh geometry={nodes.Object_169.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_171.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_172.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_173.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_174.geometry} material={materials.e180_interior} />
      <mesh geometry={nodes.Object_175.geometry} material={materials.e180_int} />
      <mesh geometry={nodes.Object_31.geometry} material={materials.glass_windows} />
      <mesh geometry={nodes.Object_33.geometry} material={materials.glass_windows} />
      <mesh geometry={nodes.Object_29.geometry} material={materials.glass_windows} />
      <mesh geometry={nodes.Object_27.geometry} material={materials.glass_windows} />
      <mesh geometry={nodes.Object_79.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_9.geometry} material={materials.sunburst_engine} />
      <mesh geometry={nodes.Object_77.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_47.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_75.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_73.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_35.geometry} material={materials.e180_glass_out} />
      <mesh geometry={nodes.Object_36.geometry} material={materials.e180_povl} />
      <mesh geometry={nodes.Object_38.geometry} material={materials.e180_glass_out} />
      <mesh geometry={nodes.Object_39.geometry} material={materials.e180_povr} />
      <mesh geometry={nodes.Object_118.geometry} material={materials.e180_short} />
      <mesh geometry={nodes.Object_119.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_120.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_121.geometry} material={materials.e180_short} />
      <mesh geometry={nodes.Object_122.geometry} material={materials.e180_long} />
      <mesh geometry={nodes.Object_134.geometry} material={materials.e180_povr} />
      <mesh geometry={nodes.Object_135.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_136.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_137.geometry} material={materials.e180_short} />
      <mesh geometry={nodes.Object_138.geometry} material={materials.e180_long} />
      <mesh geometry={nodes.Object_11.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_13.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_177.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_178.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_69.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_67.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_105.geometry} material={materials.sunburst_engine} />
      <mesh geometry={nodes.Object_65.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_63.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_140.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_141.geometry} material={materials.mirror} />
      <mesh geometry={nodes.Object_142.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_143.geometry} material={materials.indicators} />
      <mesh geometry={nodes.Object_144.geometry} material={materials.e180_glass_out} />
      <mesh geometry={nodes.Object_152.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_153.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_154.geometry} material={materials.e180_glass_out} />
      <mesh geometry={nodes.Object_155.geometry} material={materials.e180_povr} />
      <mesh geometry={nodes.Object_156.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_157.geometry} material={materials.mirror} />
      <mesh geometry={nodes.Object_61.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_59.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_57.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_45.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_24_1.geometry} material={materials.e180_povl} />
      <mesh geometry={nodes.Object_24_2.geometry} material={materials.e180_short} />
      <mesh geometry={nodes.Object_41.geometry} material={materials.e180_povr} />
      <mesh geometry={nodes.Object_42.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_130.geometry} material={materials.e180_povl} />
      <mesh geometry={nodes.Object_131.geometry} material={materials.e180_lights} />
      <mesh geometry={nodes.Object_132.geometry} material={materials.e180_tails} />
      <mesh geometry={nodes.Object_124.geometry} material={materials.e180_povr} />
      <mesh geometry={nodes.Object_125.geometry} material={materials.e180_lights} />
      <mesh geometry={nodes.Object_126.geometry} material={materials.e180_tails} />
      <mesh geometry={nodes.Object_24.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_25.geometry} material={materials.e180_glassred} />
      <mesh geometry={nodes.Object_21.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_22.geometry} material={materials.e180_glassred} />
      <mesh geometry={nodes.Object_53.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_51.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_7.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_180.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_181.geometry} material={materials.e180_red} />
      <mesh geometry={nodes.Object_182.geometry} material={materials['main_paint.001']} />
      <mesh geometry={nodes.Object_183.geometry} material={materials.e180_chrome} />
      <mesh geometry={nodes.Object_184.geometry} material={materials.e180_gray} />
      <mesh geometry={nodes.Object_185.geometry} material={materials.e180_lights} />
      <mesh geometry={nodes.Object_186.geometry} material={materials.e180_white} />
      <mesh geometry={nodes.Object_187.geometry} material={materials.e180_tail} />
      <mesh geometry={nodes.Object_188.geometry} material={materials.e180_RL} />
      <mesh geometry={nodes.Object_18.geometry} material={materials.glass_headlights} />
      <mesh geometry={nodes.Object_19.geometry} material={materials.e180_glassred} />
      <mesh geometry={nodes.Object_4.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_5.geometry} material={materials.e180_black} />
      <mesh geometry={nodes.Object_49.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_113.geometry} material={materials.e180_black} position={[0.877, 0.326, -1.464]} rotation={[Math.PI, 0, Math.PI]} />
      <mesh geometry={nodes.Object_274.geometry} material={materials.e180_black} position={[-0.877, 0.326, -1.464]} />
      <mesh geometry={nodes.Object_283.geometry} material={materials.e180_black} position={[0.877, 0.337, 1.498]} rotation={[Math.PI, 0, Math.PI]} />
      <mesh geometry={nodes.Object_292.geometry} material={materials.e180_black} position={[-0.877, 0.337, 1.498]} />
      <mesh geometry={nodes.Object_128.geometry} material={materials.glass_screen_front} />
      <mesh geometry={nodes.Object_111.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_71.geometry} material={materials.sunburst} />
      <mesh geometry={nodes.Object_55.geometry} material={materials.sunburst} />
      <group position={[0, 0.87, -2.422]} rotation={[-1.568, 0, -Math.PI]} scale={1.144}>
        <mesh geometry={nodes.Cube003_Material008_0.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cube003_Material010_0.geometry} material={materials.numberPlate} />
      </group>
      <group position={[-0.025, 0.54, 2.535]} rotation={[-1.584, 0, 0]} scale={1.176}>
        <mesh geometry={nodes.Cube002_Material008_0.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cube002_Material010_0.geometry} material={materials.numberPlate} />
      </group>
    </group>
  )
})

useGLTF.preload('assets/coolite/Corolla_2017.glb')
export default Corolla2;