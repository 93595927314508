import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_02 from '../experiments/Experiment_02';

export function Experiment_p02() {
    return (
        <>
            <Experiment_02 />
        </>
    );
}
export default Experiment_p02;