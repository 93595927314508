/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 .\ApartmentBuilding.glb -o .\ApartmentBuilding.jsx
*/

import React, { useRef } from 'react'
import { Box, Plane, useGLTF } from '@react-three/drei'

import { NodeToyMaterial, NodeToyTick } from '@nodetoy/react-nodetoy';

import { useLoader } from '@react-three/fiber'; 
import * as THREE from 'three'
import {data as InteriorShader} from './shaders/interiorMap1'

import InteriorMappingShader from './materials/interior/index.js'


export function ApartmentBuilding(props) {
  const { nodes, materials } = useGLTF('assets/Building/ApartmentBuilding.glb')


  const cubeMap = useLoader(THREE.TextureLoader, 'assets/wP_Office_v1_OF06_M_day.png')
  // const cubeMap2 = useLoader(THREE.CubeTextureLoader, 'assets/cube.dds',
  // const myMat = new NodeToyMaterial({shaderData: InteriorShader, uniforms: {nodeUniform0: {value: cubeMap}}})
  const myMat = new InteriorMappingShader({map: cubeMap})
  
  
  return (
    <group {...props} dispose={null}>
      {/* <Box args={[10, 10,10]}  position={[0,10,10]}  > */}
        {/* <NodeToyMaterial  data={InteriorShader} uniforms={{nodeUniform0: {value: cubeMap}}} /> */}
        {/* <NodeToyMaterial attach={"material"} data={InteriorShader}  /> */}
        {/* <meshBasicMaterial attach={"material"} map={cubeMap} /> */}
        {/* <InteriorMappingShader attach={"material"} map={cubeMap} /> */}
        {/* <shaderMaterial attach={"material"} {...myMat} /> */}
      {/* </Box> */}
      <group position={[-0.81, 0, -4.46]} rotation={[Math.PI / 2, 0, 0]} scale={0.03} >
        <mesh geometry={nodes.Apartment_building_16_obj.geometry} material={materials.Color_1} />
        <mesh geometry={nodes.Apartment_building_16_obj_1.geometry} castShadow={true} receiveShadow={true} material={materials.Concrete_1} />
        <mesh geometry={nodes.Apartment_building_16_obj_2.geometry} castShadow={true} receiveShadow={true} material={materials.Brick} />
        <mesh geometry={nodes.Apartment_building_16_obj_3.geometry} castShadow={true} receiveShadow={true} material={materials.Grove} />
        <mesh geometry={nodes.Apartment_building_16_obj_4.geometry} castShadow={true} receiveShadow={true} material={materials.Pave_1} />
        <mesh geometry={nodes.Apartment_building_16_obj_5.geometry} castShadow={true} receiveShadow={true} material={materials.Floor} />
        <mesh geometry={nodes.Apartment_building_16_obj_6.geometry} castShadow={true} receiveShadow={true} material={materials.Color_2} />
        <mesh geometry={nodes.Apartment_building_16_obj_7.geometry} castShadow={true} receiveShadow={true} material={materials.Metal} />
        <mesh geometry={nodes.Apartment_building_16_obj_8.geometry} castShadow={true} receiveShadow={true} material={materials.Grass} />
        <mesh geometry={nodes.Apartment_building_16_obj_9.geometry} castShadow={true} receiveShadow={true} material={materials.Wood} />
        <mesh geometry={nodes.Apartment_building_16_obj_10.geometry} castShadow={true} receiveShadow={true} material={materials.Frame} />
        <mesh geometry={nodes.Apartment_building_16_obj_11.geometry} castShadow={true} receiveShadow={true} material={materials.Glass_} />
        <mesh geometry={nodes.Apartment_building_16_obj_12.geometry} castShadow={true} receiveShadow={true} material={materials.Ramp} />
        <mesh geometry={nodes.Apartment_building_16_obj_13.geometry} castShadow={true} receiveShadow={true} material={materials.Concrete_2} />
        <mesh geometry={nodes.Apartment_building_16_obj_14.geometry} castShadow={true} receiveShadow={true} material={materials.Road} />
        <mesh geometry={nodes.Apartment_building_16_obj_15.geometry} castShadow={true} receiveShadow={true} material={materials.Pave_2} />
      </group>
    </group>
  )
}

useGLTF.preload('assets/ApartmentBuilding.glb')

export default ApartmentBuilding;

