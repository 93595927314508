import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils';
import React,{useState} from 'react';
// import { Carousel, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Card, Typography,Box} from '@mui/material';
import Container from "@mui/material/Container"
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import { sizing } from '@mui/system';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import positions from '@mui/system';
import {useEffect} from "react";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
// import './gallery.css';
import './vfx.css';
import CloseIcon from '@mui/icons-material/Close';
import JsonData from '../serviceImageGallery.json'

// import headerVideo1 from './assets/slide1.mp4';
// import headerVideo2 from './assets/slide_da2.mp4';
// import headerVideo3 from './assets/slide_tower.mp4';
const VfxImages = () => {

    var items = [
        {
            name: "Blender",
            file: "/images/vfxStackImg/blender.png",
            description: "Blender image"
        },
        {
            name: "Docker",
            file: "/images/vfxStackImg/docker.png",
            description: "Docker image"
        },
        {
            name: "Gimp",
            file: "/images/vfxStackImg/gimp.png",
            description: "Gimp image"
        },
        {
            name: "Kitsu",
            file: "/images/vfxStackImg/kitsu.png",
            description: "Kitsu image"
        },
        {
            name: "Natron",
            file: "/images/vfxStackImg/natron.png",
            description: "Natron image"
        },
        {
            name: "Nc2",
            file: "/images/vfxStackImg/nc2.png",
            description: "Nc2 image"
        },
        {
            name: "Npm",
            file: "/images/vfxStackImg/npm.png",
            description: "Npm image"
        },
        {
            name: "Prism",
            file: "/images/vfxStackImg/prism.png",
            description: "Prism image"
        },
        {
            name: "Resolve",
            file: "/images/vfxStackImg/resolve.png",
            description: "Resolve image"
        },
        {
            name: "Rocket",
            file: "/images/vfxStackImg/rocket.png",
            description: "Rocket image"
        },
        {
            name: "Unity",
            file: "/images/vfxStackImg/unity.png",
            description: "Unity image"
        },
        {
            name: "Unreal",
            file: "/images/vfxStackImg/unreal.png",
            description: "Unreal image"
        }
        
    ]
    
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundImage: 'url(/images/vfxStackImg/bg.jpg)', backgroundSize: 'cover', maxHeight: '100vh' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {items.slice(0, 6).map((item, index) => (
                        <Card  key={index} style={{ margin: '10px', width: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'none',backgroundColor: 'transparent' }}>
                            <img
                                src={item.file}
                                alt={item.name}
                                style={{ height: '60px', objectFit: 'contain' }}
                            />
                            <Typography gutterBottom variant="h5" component="div">
                                {item.name}
                            </Typography>
                        </Card>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {items.slice(6, 12).map((item, index) => (
                        <Card key={index} style={{ margin: '10px', width: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'none',backgroundColor: 'transparent' }}>
                            <img
                                src={item.file}
                                alt={item.name}
                                style={{ height: '60px', objectFit: 'contain' }}
                            />
                            <Typography gutterBottom variant="h5" component="div">
                                {item.name}
                            </Typography>
                        </Card>
                    ))}
                </div>
            </div>
            {/* next section */}
  
            <div style={{ backgroundImage: 'url(/images/pipeImg/about_bg.jpg)', backgroundSize: 'cover' , paddingBottom: '100px'   }}>

                <Grid container spacing={2}>
                    <Grid item xs={4}>

                    <div style={{ position:"relative", width:"50%",height:"60%", left:"30%",top:"5%",marginRight:"10%",paddingTop:"70px"}}>

                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h4" color="common.white">FOSS PIPELINE</Typography>
                    <p >In early 2019, we had been contracted by Film Factory to setup Minimalist VFX Pipeline for them with No Cost attached using Foss Tools.Due to covid, need for increased Remote work is forcing cause behind this.</p>
                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h6" color="common.white">FEATURES</Typography>
                    <Typography  align = "left" marginLeft={5} fontSize={18} >
                    <ul>
                        <li>Docker - Server Management</li>
                        <li>NC - Self Hosted Cloud(Alternate to Dropbox / Google Drive)</li>
                        <li>Kitsu - Production Managemente</li>
                        <li>Prism - Project Management</li>
                    </ul>
                    </Typography>
                    


                    </div>

                    </Grid>
                    <Grid item xs={8}>

                    <div style={{ position:"relative", left:"auto",top:"5%",marginLeft:"auto"}}>
                    <Typography sx = {{fontWeight:'bold'}} variant="h3" color="common.white" align = "left">INTRODUCTION</Typography>
                    </div>
                    <div style={{position:"relative", width:"50%", left:"auto",top:"7%",marginLeft:"20px"}}>
                    <Typography sx = {{fontSize:'1rem',fontWeight:'bold' }} align = "justify" variant="h6" color="common.white">Stop Re-Inventing The Wheel Just Start The Ride</Typography>
                    </div>
                    <div style={{ position:"relative", left:"0%",top:"10%"}}>
                    <Container maxWidth = "xl" position = "relative" maxHeight = "lg" >
                    <img src = "/images/pipeImg/diagram_v1.png" alt = "Film Factory" width = "80%" maxHeight = "50%" />
                    </Container>                        
                    </div>

                    
                    </Grid>
                </Grid>
                

                </div>


                  



                
                

            
    </>
    );

}
export default VfxImages;