
function getVideos() {
    return [
    { 
        "poster": "aivideos/BrigitteCelidot_v4.png", 
        "src": "aivideos/BrigitteCelidot_v4.mp4",
        "category": "9Hundred" 
    },{ 
        "poster": "aivideos/AnthonyTabar_v4.png", 
        "src": "aivideos/AnthonyTabar_v4.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/AngelaYang_v4.png", 
        "src": "aivideos/AngelaYang_v4.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/AnilSharma_v5.png", 
        "src": "aivideos/AnilSharma_v5.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/AndrewBurger_v4.png", 
        "src": "aivideos/AndrewBurger_v4.mp4",
        "category": "9Hundred"
        
    },{ 
        "poster": "aivideos/AliMoini_v4.png", 
        "src": "aivideos/AliMoini_v4.mp4",
        "category": "9Hundred"
        
    },{ 
        "poster": "aivideos/CostasKivelos_v1.png", 
        "src": "aivideos/CostasKivelos_v1.mp4",
        "category": "9Hundred" 
    },{ 
        "poster": "aivideos/DanielZareh_v1.png", 
        "src": "aivideos/DanielZareh_v1.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/ElliotMacgregor_v4.png", 
        "src": "aivideos/ElliotMacgregor_v4.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/GeetaMistry_v4.png", 
        "src": "aivideos/GeetaMistry_v4.mp4",
        "category": "9Hundred"
    },{ 
        "poster": "aivideos/RickBhatti_v1.png", 
        "src": "aivideos/RickBhatti_v1.mp4",
        "category": "9Hundred"
        
    },{ 
        "poster": "aivideos/Todor_Yordanov_v1.png", 
        "src": "aivideos/Todor_Yordanov_v1.mp4",
        "category": "9Hundred"
        
    }, { 
        "poster": "aivideos/kalaChashma.png", 
        "src": "aivideos/kalaChashma.mp4",
        "category": "Toon,Dance,Stylized"
        
    },{ 
        "poster": "aivideos/vivianeChoi_v1.png", 
        "src": "aivideos/vivianeChoi_v1.mp4",
        "category": "Toon,Dance,Stylized" 
    },{ 
        "poster": "aivideos/animeStyle.png", 
        "src": "aivideos/animeStyle.mp4",
        "category": "Toon,Stylized" 
    }
];
}

export default getVideos;