import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils';
import React,{useState} from 'react';
// import { Carousel, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Card, CardContent, CardMedia, Typography,Container} from '@mui/material';
import {useEffect} from "react";
// import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Box from '@mui/material/Box';
// import './gallery.css';
import './vfxGallery.css';
import { ImageList, ImageListItem, ImageListItemBar,Grid} from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import ImageModal from "./ImageModal";
import CloseIcon from '@mui/icons-material/Close';
import JsonData from '../serviceImageGallery.json'

// import headerVideo1 from './assets/slide1.mp4';
// import headerVideo2 from './assets/slide_da2.mp4';
// import headerVideo3 from './assets/slide_tower.mp4';
const ImageGallery = () => {

    
    return (
        <>
        <div>
            <h1>SEE IT IN ACTION</h1>
            <h2>We provide training and help you manage your wikis as well.</h2>
        </div>
        

          


        <div alignItems = "center" style={{paddingTop:"40px",marginLeft:"25%"}}>
        <Carousel showThumbs = "false" width = "70%"  >
                            <div>
                                <img src="/images/pipeImg/kitsu1.jpg"/>
                                {/* <p className="legend">Legend 1</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/kitsu2.jpg"/>
                                {/* <p className="legend">Legend 2</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/kitsu3.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/prism1.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/prism2.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/wip1.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/wip2.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/wip3.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/pipeImg/wip4.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                        </Carousel>
        </div>


        <div>
            <h1>EVOLUTION PHASE</h1>
            <h3 style={{color : "#ffffff"}}>Here are key team members</h3>


            <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <div style={{ position:"relative", width:"50%",height:"60%", left:"30%",top:"2%",marginRight:"10%",paddingTop:"20px"}}>
                    <Typography sx = {{fontWeight:'Thin' }} align = "left" variant="h4" color="common.white" marginTop={4}>Future Improvements</Typography>
                    <p >Currently it has few limitations(we feel) and we are already working on improved version.</p>
                    <Typography sx = {{fontWeight:'Thin' }} align = "left" variant="h5" color="common.white">More Details</Typography>
                    <Typography  align = "left" marginLeft={5} fontSize={18} >
                    <ul>
                        <li>LDAP Integration</li>
                        <li>Rocket Chat Integration</li>
                        <li>Local Render Farm Support(On Premise)</li>
                        {/* <li>Prism - Project Management</li> */}
                    </ul>
                    </Typography>
                    </div>


                    </Grid>
                    <Grid item xs={8}>
                    <div style={{ position:"relative", left:"0%",top:"10%"}}>
                    <Container maxWidth = "xl" position = "relative" maxHeight = "lg" >
                    <img src = "/images/pipeImg/diagram_v2.png" alt = "diagram v2" width = "80%" maxHeight = "60%" />
                    </Container>                        
                    </div>

                    
                    </Grid>
                </Grid>
        </div>
        </>
      );

}
export default ImageGallery;
