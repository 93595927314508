
import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, Sphere } from '@react-three/drei'
import { OrbitControls, Stats, useTexture } from "@react-three/drei";
import { ringGeometry, planeGeometry, Text } from "@react-three/drei";
import { Environment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import {Grid,ButtonGroup,Button,Typography,Slider, Slide} from '@mui/material';
// import { superman } from "./Honda_Civic";
import Checkbox from '@mui/material/Checkbox';
import { useSpring } from "@react-spring/core"
import { useControls, Leva, button, buttonGroup} from 'leva'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'
import { useProgress } from "@react-three/drei";

import { a } from "@react-spring/web"

// import {HondaCivic} from './HondaCivic'
// import {HondaCivic2} from './HondaCivic2'
import HondaCivic from './Honda_Civic';






import './experiments.css'

const Loading = <Html><div>LOADING...</div></Html>;
function Loading3() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  useEffect(() => {
    console.log(active, progress)
  }, [active, progress])
  return (
    <>
    {Math.floor(progress)} % loaded
    </>
    )
}
function Ground(props) {
  const [floor, normal] = useTexture(['assets/SurfaceImperfections003_1K_var1.jpg', 'assets/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[1, 1]} {...props} />}
    </Reflector>
    
  )
}

function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();
  
  const [baseColor,setBaseColor] = useState(props.color);

  useEffect(() => {
    setBaseColor(props.color);
    applyProps(refMat, {color: props.color});
    //applyProps(materials.body, { color: props.activeColor });
    //applyProps(materials.body, { roughness: props.activeRoughness });
    //applyProps(materials.body,{metalness: props.activeMetal})
  },[props.color]);
  

  return (
    <group position={[0, -.01, 0]}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial ref={refMat}
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={90}
          metalness={.2}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          // color={props.color}
          color="#050505"
        />
      </mesh>
    </group>
  
   )
}
function Box(props) {
    const ref = useRef()
  
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
  
    useFrame((state) => {
      ref.current.rotation.x += props.up ? -0.1 : 0
      ref.current.rotation.x += props.down ? 0.1 : 0
      ref.current.rotation.y += props.left ? -0.1 : 0
      ref.current.rotation.y += props.right ? 0.1 : 0
    }, [])
  
    return (
      <mesh
        position={props.position}
        ref={ref}
        scale={clicked ? 1.5 : 1}
        onClick={(event) => click(!clicked)}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}>
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? 'red' : 'blue'} />
      </mesh>
    )
  }
export function Experiment_03() {

    const [parentState, setParentState] = useState("initial state");
    const [activeColor, setActiveColor] = useState("#002e63");
    const [activeRoughness, setActiveRoughness] = useState(0.25);
    const [activeMetal,setActiveMetal] = useState(1.0)
    const [hex, setHex] = useState("#fff");
    const [toggle, setToggle] = useState(true);
    const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])
    const [bgColor, setBgColor] = useState("#000");
    const [myText, setMyText] = useState("Hello World");
    const r1 = useRef();
    const r2 = useRef();
    const r3 = useRef();
    const [showLoading, setShowLoading] = useState(true);
    
    const rings = [r1, r2, r3];
    const oc = useRef();
    const [autorotate, setAutoRotate] = useState(true);
    const toggleAutoRotate = () => {
      setAutoRotate((prev) => !prev);
      oc.autoRotate = !oc.autoRotate;
    };
    const onChangeShcema = schemaID => {
      console.log("here");
    };
    const updateColor = (color) => {
      console.log("Updating Color", color);
      setActiveColor(color);
    }
    const updateBgColor = (color) => {
      console.log("Updating BgColor", color);
      setBgColor(color);
    }
    const updateRoughness = (event, roughness) => {
      console.log(roughness);
      setActiveRoughness(roughness);
    };
    const updateMetal = (event,metal) =>{
      setActiveMetal(metal)
    }

    const randomizeRingGeoPositions = () => {
      console.log("randomizing ring geo positions")
      // iterate through the rings and set their positions to random values
      for (let i = 0; i < rings.length; i++) {
        if (rings[i].current) {
          rings[i].current.position.x = Math.random() * 10 - 5;
          rings[i].current.position.z = Math.random() * 10 - 5;
          rings[i].current.position.y = Math.random() * 0.2;
          var ringeo = rings[i].current.geometry;
          console.log(ringeo.parameters.innerRadius);
          rings[i].current.geometry.parameters.innerRadius = Math.random() * 2;
          rings[i].current.geometry.parameters.outerRadius = (Math.random()*2.1) + rings[i].current.geometry.parameters.innerRadius;
          rings[i].current.geometry.parameters.thetaSegments = Math.floor(Math.random() * 7) + 3;
          rings[i].current.geometry.parameters.phiSegments = Math.floor(Math.random() * 7) + 3;
          rings[i].current.geometry.parameters.thetaStart = Math.random() * 2 * Math.PI;
          rings[i].current.geometry.parameters.thetaLength = Math.random() * 2 * Math.PI;
          rings[i].current.material.color = new THREE.Color(Math.random(), Math.random(), Math.random());
        }
      }     
    };
    // const { setCarBodyPaintColor } = useHondaCivic();

    // const handleClick = () => {
    //   setCarBodyPaintColor('#f00');
    // };

    // const adjustBackground = (color) => {
    //   console.log("adjusting background color to " + color)
    //   setBgColor(color);
    //   // oc.background = new THREE.Color(color);
    // };
    // const data = useControls("ENVIRONMENT",{
    //   AutoRotate: {
    //     value: true,
    //     onChange: (v) => {
    //       // imperatively update the world after Leva input changes
    //       console.log(v)
    //       setAutoRotate(v)
    //     }
    //   },
    //   // Randomize: {
    //   //   value: false,
    //   //   onChange: (v) => {
    //   //     // imperatively update the world after Leva input changes
    //   //     console.log("going to randommize ring geo positions")
    //   //     randomizeRingGeoPositions()
    //   //   }
    //   // },
    //   "RANDOMIZE SHAPES": button(() => randomizeRingGeoPositions(), ),
    //   // "GREEN": button(() => randomizeRingGeoPositions(), { color: "#f00" , label: "GREEN"}),
      

    //   // bodyColor: { value: "#fff", label: "Body Color" ,
    //   //   onClick: (v) => {
    //   //     console.log(v)
    //   //   }
    //   // },
    //   "BACKGROUND": buttonGroup({
    //     "Dark": () => {adjustBackground('#aaa')},
    //     "Grey": () => {adjustBackground('#ccc')},
    //     "Bright": () => {adjustBackground('#eee')},
    //   }),
      
    // })
    
    return (
        <>
        <div style = {{height : "100%"}}>
        <Grid container spacing={0} sx = {{height : "100%"}}>
        <Grid item xs={12} sx={{position:"relative", zIndex:1}}>
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ position: [8, 2, 8], fov: 45 }}>
        <color attach="background" args={[bgColor]} />
        <Suspense fallback={showLoading ? <Html center><Loading3 /></Html> : null}>        
        <hemisphereLight intensity={0.25} />
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <ContactShadows resolution={1024} frames={1} position={[0, .01, 0]} scale={15} blur={0.5} opacity={1} far={20} />
        <Ground2 color={bgColor}/>
        <mesh ref={r1} scale={4} position={[-3, 0, -1]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
          <ringGeometry args={[0.9, .95, 3, 1]} />
          <meshStandardMaterial color="yellow" roughness={0.75} />
        </mesh>
        <mesh ref={r2} scale={3} position={[3, 0, 3]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <ringGeometry args={[0.9, 1, 3, 3]} />
          <meshStandardMaterial color="green" roughness={0.75} />
        </mesh>
        <mesh ref={r3} scale={3} position={[5, 0, -5]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <ringGeometry args={[1.3, 1.35, 50, 3]} />
          <meshStandardMaterial color="red" roughness={0.75} />
        </mesh>
        <HondaCivic scale={1.5} parentState={parentState} activeColor={activeColor} activeRoughness={activeRoughness} activeMetal = {activeMetal} />
        maxPolarAngle="Math.PI/2"
        <Environment resolution={2048}>
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer form="ring" color="blue" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
          <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
        </EffectComposer>
      <OrbitControls ref={oc} target={[0,0,0]} autoRotate={autorotate}  maxPolarAngle={Math.PI/2.1} />
      </Suspense>
      </Canvas>
      </Grid>
      <Grid item xs={3} sx={{position:"absolute", zIndex:1,right:"0%",justifyContent:"right"}}>
      <div style={{backgroundColor:"#181c20", height:"100%",padding:"10px", margin:"10px"}}>
        <Typography sx = {{color :"white"}}>Environment</Typography>
        <Typography sx = {{color :"grey"}} >AutoRotate</Typography>
        <Checkbox onChange={toggleAutoRotate} checked={autorotate} sx={{color: "grey"}} />
        <Button variant = "contained" color ="primary" onClick={randomizeRingGeoPositions}>RANDOMIZE SHAPES</Button>
        <Typography>Background
        <ButtonGroup variant="text" aria-label="outlined primary button group">
          <Button onClick={() => updateBgColor("#000")} >Dark</Button>
          <Button onClick={() => updateBgColor("#aaa")}>Grey</Button>
          <Button onClick={() => updateBgColor("#fff")}>Bright</Button>
        </ButtonGroup>
        </Typography>
        <Typography sx ={{marginTop:"10px",color:"grey"}}>BodyPaint</Typography>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick={() => updateColor("#8b0000")} sx = {{backgroundColor : "red",height:"20px",width:"20px",'&:hover': {backgroundColor: '#e58e73'}}}></Button>
          <Button onClick={() => updateColor("#4e6e81")} sx = {{backgroundColor : "#4e6e81",height:"20px",width:"20px",'&:hover': {backgroundColor: '#4e6e81'}}}></Button>
          <Button onClick={() => updateColor("#006400")} sx = {{backgroundColor : "green",height:"20px",width:"20px",'&:hover': {backgroundColor: '	#90EE90'}}}></Button>
          <Button onClick={() => updateColor("#ff8c00")} sx = {{backgroundColor : "orange",height:"20px",width:"20px",'&:hover': {backgroundColor: '	#FFD580'}}}></Button>
          <Button onClick={() => updateColor("#002e63")} sx = {{backgroundColor : "navy",height:"20px",width:"20px",'&:hover': {backgroundColor: '#2E5A88'}}}></Button>
          <Button onClick={() => updateColor("#C0C0C0")} sx = {{backgroundColor : "silver",height:"20px",width:"20px",'&:hover': {backgroundColor: '#D8D8D8'}}}></Button>
          <Button onClick={() => updateColor("#808080")} sx = {{backgroundColor : "grey",height:"20px",width:"20px",'&:hover': {backgroundColor: '#D3D3D3'}}}></Button>
        </ButtonGroup>
        <Typography>Roughness</Typography>
        <Slider defaultValue={0.25} aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} onChange={updateRoughness} min ={0} max = {1} step ={0.01} />
        <Typography>Metal</Typography>
        <Slider defaultValue={1} aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} onChange={updateMetal} min ={0} max = {1} step ={0.01} />
      </div>  
      </Grid>
      </Grid>
      </div>
      </>
    )
}
export default Experiment_03;