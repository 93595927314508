import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
// import { Suspense } from "react";
import Typography from "@mui/material/Typography";
import TimePicker from "@mui/lab/TimePicker";
import DateAdapter from "@mui/lab/AdapterDateFns";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { LocalizationProvider } from '@mui/x-date-pickers'
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid'
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

import { Canvas, useFrame } from '@react-three/fiber'
import { Html, Sphere } from '@react-three/drei'
import { OrbitControls, Stats, Text } from "@react-three/drei";
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"

import { Model } from "../experiments/Model";

import axios from "axios";
import './Contact.css'

const StyledForm = styled.form`
  width: 500px;
  padding: 10px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
`;

const Loading = <Html><div>LOADING...</div></Html>;



function Box(props) {
    const ref = useRef()
  
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
  
    useFrame((state) => {
      ref.current.rotation.x += props.up ? -0.1 : 0
      ref.current.rotation.x += props.down ? 0.1 : 0
      ref.current.rotation.y += props.left ? -0.1 : 0
      ref.current.rotation.y += props.right ? 0.1 : 0
    }, [])
  
    return (
      <mesh
        position={props.position}
        ref={ref}
        scale={clicked ? 1.5 : 1}
        onClick={(event) => click(!clicked)}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}>
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? 'red' : 'blue'} />
      </mesh>
    )
  }
export function Contact(props) {
  useEffect(() => {
    console.log('here')
    // [4,6,-4]
    

  }, []);
  // const inputRef = useRef(null); // 1. create

  // const [up, toggleUp] = useState(false)
  // const [down, toggleDown] = useState(false)
  // const [left, toggleLeft] = useState(false)
  // const [right, toggleRight] = useState(false)
  const [toggle, set] = useState(0)
  // Set up a shared spring which simply animates the toggle above
  // We use this spring to interpolate all the colors, position and rotations
  const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

  const [myText, setMyText] = useState("Hello World");
  const [purpose, setPurpose] = useState();
  const [dishName, setDishName] = useState(myText);
  const [type, setType] = useState("");
  const [no_of_slices, setNumOfSlices] = useState("");
  const [diameter, setDiameter] = useState("");
  const [spicines, setSpicines] = useState(1);
  const [error, setError] = useState({});
  
  const [preperation_time, setPreperation_time] = useState(
    new Date("2022-03-01T00:00:00")
  );
  const marks = [
    {value: 1, label: "1",},
    {value: 10,label: "10",},
  ];

  const updateText = (text) => {
    setMyText(text);
    setDishName(text);
  }
  const purposeChange = (e) => {
    setPurpose(e.target.value);
  }
  const focuschange = (e,opt) => {
    console.log(e.target);
    console.log(e.target.value);
    // console.log(s1); // current mesh
    // console.log(s2); // current mesh
    // console.log(s3); // current mesh
    // console.log(s1.current); // Mesh
    console.log(s1.current.position); // Position

    // console.log(oc); // current orbit control
    // console.log(oc.current); // OrbitControls
    console.log(oc.current.target); // OrbitControls target object (to look at vector 3)
    if (opt === '1') {
      oc.current.target = s1.current.position;
    }
    else if (opt === '2') {
      oc.current.target = s2.current.position;
    }
    else if (opt === '3') {
      oc.current.target = s3.current.position;
    }
  }
  
  const chandleSubmit = (e) => {
    const data = {
      name: dishName,
      preparation_time: preperation_time.toLocaleTimeString(),
      type: type.toLowerCase(),
      ...(type === "PIZZA" && {
        no_of_slices: parseInt(no_of_slices),
      }),
      ...(type === "SANDWICH" && {
        slices_of_bread: parseInt(no_of_slices),
      }),
      ...(type === "PIZZA" && {
        diameter: parseFloat(diameter),
      }),
      ...(type === "SOUP" && {
        spiciness_scale: spicines,
      }),
    };

    const handleSucces = () => {
      setDishName("");
      setPreperation_time("2022-03-01T00:00:00");
      setType("");
      setNumOfSlices("");
      setDiameter("");
      setSpicines(0);
      setError({});
    };

    axios
      .post(
        "https://frosty-wood-6558.getsandbox.com:443/dishes",
        JSON.stringify(data),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((e) => console.log(e.data))
      .then(() => handleSucces())
      .catch((e) => setError(e.response.data));

    e.preventDefault();
    
  };
  const s1 = useRef();
  const s2 = useRef();
  const s3 = useRef();
  const oc = useRef();
    return ( 
        <>
         <Grid container rowSpacing={1.5} >
         <Grid item xs={9} display="flex" justifyContent="center" >  
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ fov: 45, position: [20, 5, 15] }}>
        <Suspense fallback={ Loading }> 
        <Html fullscreen>
            
        </Html>

        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        {/* <Box position={[0, 0, 0]} up={up} down={down} left={left} right={right} /> */}
        <Box position={[8, 0, -0]} x={x} set={set} />
        <Text
          
          scale={[1,1,1]}
          color="black" // default
          anchorX="center" // default
          anchorY="middle" // default
        >
          {myText}
        </Text>
        <Model />
        <Sphere ref={s1} position={[6,2,-8]} scale={.5}  />
        <Sphere ref={s2}position={[-1,2,1]} scale={.5}  />
        <Sphere ref={s3} position={[-3,8,-8]} scale={.5}  />
        
      <OrbitControls ref={oc} target={[4,6,-4]}/>
      <Stats/>
      </Suspense>
        </Canvas>
       </Grid>   
        
        
    <Grid item xs={3} display="flex" justifyContent="flex-end">
      <StyledForm onSubmit={(e) => chandleSubmit(e)} >
        <Stack spacing={5}>
          <Typography variant="h3" component="h2" sx={{ margin: "0 auto" }}>
            Write Us
          </Typography>

          {/* <InputLabel id="demo-simple-select-label">Purpose</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={10}
            label="Age"
            onChange={purposeChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select> */}
          
          <TextField label="Name"  value={dishName} required onChange={(e) => updateText(e.target.value) } error={!!error.name} helperText={error.name} />
          <TextField label="Email" value={dishName} required error={!!error.name} helperText={error.name} />
          <TextField label="Phone" value={dishName} required error={!!error.name} helperText={error.name} />

          <LocalizationProvider dateAdapter={DateAdapter}>
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              label="Preparation time"
              value={preperation_time}
              required
              onChange={(newValue) => {
                setPreperation_time(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <FormHelperText
              sx={{ color: "red", position: "absolute", top: "315px" }}
            >
              {error.preparation_time}
            </FormHelperText>
          </LocalizationProvider>

          <FormControl error={!!error.type}>
            <FormLabel>Purpose</FormLabel>
            <RadioGroup row>
              <FormControlLabel control={<Radio required={true} value="Project Query" />} checked={type === "Project Query"} label="Project Query" onChange={() => setType("Project Query")} onFocus={(e) => focuschange(e,'1')} onSubmit={(e) => (e.target.value = false)} />
              <FormControlLabel control={<Radio required={true} value="Work / Job" />}  checked={type === "Work / Job"}  label="Work / Job"  onChange={() => setType("Work / Job")} onFocus={(e) => focuschange(e,'2')} />
              <FormControlLabel control={<Radio required={true} value="Misc/Other" />} checked={type === "Misc/Other"} label="Misc/Other" onChange={() => setType("Misc/Other")} onFocus={(e) => focuschange(e,'3')} />
            </RadioGroup>
            <FormHelperText>{error.type}</FormHelperText>
          </FormControl>
          
          {type === "Project Query" || type === "Misc/Other" ? (

            <TextField
              label="Number of slices"
              value={no_of_slices}
              required
              inputProps={{
                inputMode: "decimal",
              }}
              onChange={(e) => {
                setNumOfSlices(e.target.value.replace(/\D/g, ""));
              }}
              error={!!error.no_of_slices || !!error.slices_of_bread}
              helperText={error.no_of_slices || error.slices_of_bread}
            />
          ) : (
            ""
          )}

          {type === "Project Query" ? (
            <TextField
              label="Diameter"
              type="number"
              value={diameter}
              onChange={(e) => setDiameter(e.target.value)}
              error={!!error.diameter}
              helperText={error.diameter}
              inputProps={{
                inputMode: "numeric",
                step: "0.01",
              }}
            />
          ) : (
            ""
          )}

          {type === "Work / Job" ? (
            <FormControl>
              <FormLabel>Spiciness</FormLabel>
              <Slider
                value={spicines}
                required
                onChange={(e) => setSpicines(e.target.value)}
                min={1}
                max={10}
                step={1}
                marks={marks}
                valueLabelDisplay="auto"
              />
            </FormControl>
          ) : (
            ""
          )}

          <Button type="submit" variant="contained">
            SUBMIT
          </Button>
        </Stack>
      </StyledForm>
    </Grid>
        </Grid>
        </>
    )
}

export default Contact;