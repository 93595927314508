import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_05 from '../experiments/Experiment_05';

export function Experiment_p05() {
    return (
        <>
            <Experiment_05 />
        </>
    );
}
export default Experiment_p05;