
function getCooliteItems() {
    return [
    { 
        "poster": "assets/coolite/coolite_20.png",
        "detail": "HP Charcoal 20", 
        "thumb": "assets/coolite/thumb_20.png",
        "data": {
            'vlt':22,
            'vlr': 8,
            'uva/uvb': 99,
            'ts': 54,
        }
    },{ 
        "poster": "assets/coolite/coolite_32.png", 
        "detail": "HP Charcoal 32", 
        "thumb": "assets/coolite/thumb_32.png",
        "data": {
            'vlt':32,
            'vlr': 10,
            'uva/uvb': 98,
            'ts': 53,
        }
    },{ 
        "poster": "assets/coolite/coolite_50.png",
        "detail": "HP Charcoal 50", 
        "thumb": "assets/coolite/thumb_50.png",
        "data": {
            'vlt':50,
            'vlr': 11,
            'uva/uvb': 98,
            'ts': 40,
        }
    },
    { 
        "poster": "assets/coolite/coolite_80.png", 
        "detail": "Nano Hybrid 80", 
        "thumb": "assets/coolite/thumb_80.png",
        "data": {
            'vlt':80,
            'vlr': 7,
            'uva/uvb': 99,
            'ts': 44,
        }
    }
];
}

export default getCooliteItems;