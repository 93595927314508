/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 sphere_v6.gltf -o sphere.jsx
*/

import React, { useRef } from 'react'
import { useState, useMemo, useEffect } from 'react'
import { applyProps } from '@react-three/fiber'


import { useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'; 
import { TextureLoader } from 'three/src/loaders/TextureLoader'; 
import * as THREE from 'three'

import { useControls } from 'leva'



export function PeopleSphere(props) {
  // CONSTS
  const { nodes, materials } = useGLTF('assets/people/assets/sphere_v6.gltf')
  const cards = ['card001','card002','card003','card004'];
  const topHalf = ['ptr_00','ptr_01', 'ptr_02','ptr_03','ptr_04','ptr_05','ptr_06'];
  const lowerHalf = ['ptr_07','ptr_08', 'ptr_09','ptr_10','ptr_11','ptr_12','ptr_13'];
  const icons = ['icon_00','icon_01', 'icon_02','icon_03','icon_04','icon_05','icon_06','icon_07','icon_08', 'icon_09','icon_10','icon_11','icon_12','icon_13'];
  const iconTextures = ['ptr_00.png','ptr_01.png', 'ptr_02.png','ptr_03.png','ptr_04.png','ptr_05.png','ptr_06.png','ptr_07.png','ptr_08.png', 'ptr_09.png','ptr_10.png','ptr_11.png','ptr_12.png','ptr_13.png'];
  const [icon_00, icon_01, icon_02, icon_03, icon_04, icon_05, icon_06, icon_07, icon_08, icon_09, icon_10, icon_11, icon_12, icon_13] = useLoader(TextureLoader, [ 
    'assets/people/assets/tiles/ptr_00.png',
    'assets/people/assets/tiles/ptr_01.png', 
    'assets/people/assets/tiles/ptr_02.png', 
    'assets/people/assets/tiles/ptr_03.png', 
    'assets/people/assets/tiles/ptr_04.png', 
    'assets/people/assets/tiles/ptr_05.png', 
    'assets/people/assets/tiles/ptr_06.png', 
    'assets/people/assets/tiles/ptr_07.png', 
    'assets/people/assets/tiles/ptr_08.png', 
    'assets/people/assets/tiles/ptr_09.png', 
    'assets/people/assets/tiles/ptr_10.png', 
    'assets/people/assets/tiles/ptr_11.png',
    'assets/people/assets/tiles/ptr_12.png',  
    'assets/people/assets/tiles/ptr_13.png'
  ])
  icon_00.flipY = icon_01.flipY = icon_02.flipY = icon_03.flipY = icon_04.flipY = icon_05.flipY = icon_06.flipY = icon_07.flipY = icon_08.flipY = icon_09.flipY = icon_10.flipY = icon_11.flipY = icon_12.flipY = icon_13.flipY = false;
  // icon_00.rotation=180;
  const iconMaps = [icon_00, icon_01, icon_02, icon_03, icon_04, icon_05, icon_06, icon_07, icon_08, icon_09, icon_10, icon_11, icon_12, icon_13];

  const [topcolor, setTopColor] = useState('#00f');
  const [bottomcolor, setBottomColor] = useState('#f00');
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);

  // Leva controls
  const data = useControls({
    TopColor: {
      value: '#00f',
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        console.log(v)
        setTopColor(v)
      }
    },
    BottomColor: {
      value: '#f00',
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        console.log(v)
        setBottomColor(v)
      }
    },
  })

  // create 2 new materials and append to materials list
  materials.glassBlue = materials.glass.clone()
  materials.glassRed = materials.glass.clone()
  // rename the materials
  materials.glassBlue.name = 'glassBlue'
  materials.glassRed.name = 'glassRed'
  // set the color of the materials
  materials.glassBlue.color.set('#00f')
  materials.glassRed.color.set('#f00')
  applyProps(materials.glassBlue, { color: topcolor });
  applyProps(materials.glassRed, { color: bottomcolor });

  Object.values(iconMaps).forEach((icon) => {
    let newname = "icon_" + icon.source.data.src.split('/').pop().split('.')[0].split('_')[1];
    
    materials[newname] = materials.icon.clone();
    materials[newname].name = newname;
    applyProps(materials[newname], { map: icon, alphaMap: icon, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1 });
    //applyProps(materials[newname], { color: {hovered ? 'hotpink' : 'orange'} });

  })

  const itemClicked = (e) => {
    // console.log('clicked',e);
    click(!clicked);
  }
  const itemHoveredStart = (e) => {
    // console.log('hovered');
    // console.log("EVENT:",e);
    // console.log("TARGET:",e.target);
    // console.log("OBJECT:",e.object);
    // console.log("OBJECT MATERIAL:",e.object.material);
    e.object.material.color.set('hotpink');
    e.object.translateOnAxis(new THREE.Vector3(0, 1, 0), 0.1);
    hover(true);
  }
  const itemHoveredEnd = (e) => {
    // console.log('hovered end');
    // console.log(e.target);
    e.object.material.color.set('orange');
    e.object.translateOnAxis(new THREE.Vector3(0, 1, 0), -0.1);
    hover(false);
  }

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.icon_00.geometry} material={materials.icon_00} position={[0, 0.91, 0]} scale={0.9} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}}/>
      <mesh geometry={nodes.icon_01.geometry} material={materials.icon_01} position={[0.84, 0.48, 0]} rotation={[0, 0, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_02.geometry} material={materials.icon_02} position={[0.42, 0.48, -0.72]} rotation={[0, Math.PI / 3, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_03.geometry} material={materials.icon_03} position={[-0.42, 0.48, -0.72]} rotation={[Math.PI, Math.PI / 3, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_04.geometry} material={materials.icon_04} position={[-0.84, 0.48, 0]} rotation={[Math.PI, 0, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_05.geometry} material={materials.icon_05} position={[-0.42, 0.48, 0.72]} rotation={[Math.PI, -Math.PI / 3, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_06.geometry} material={materials.icon_06} position={[0.42, 0.48, 0.72]} rotation={[0, -Math.PI / 3, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_07.geometry} material={materials.icon_07} position={[0.84, -0.48, 0]} rotation={[-Math.PI, 0, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_08.geometry} material={materials.icon_08} position={[0.42, -0.48, -0.72]} rotation={[-Math.PI, -Math.PI / 3, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_09.geometry} material={materials.icon_09} position={[-0.42, -0.48, -0.72]} rotation={[0, -Math.PI / 3, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_10.geometry} material={materials.icon_10} position={[-0.84, -0.48, 0]} rotation={[0, 0, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_11.geometry} material={materials.icon_11} position={[-0.42, -0.48, 0.72]} rotation={[0, Math.PI / 3, 2.09]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_12.geometry} material={materials.icon_12} position={[0.42, -0.48, 0.72]} rotation={[-Math.PI, Math.PI / 3, -Math.PI / 3]} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />
      <mesh geometry={nodes.icon_13.geometry} material={materials.icon_13} position={[0, -0.91, 0]} rotation={[0, 0, Math.PI]} scale={0.9} onPointerOver={(e) => itemHoveredStart(e)} onPointerOut={(e) => itemHoveredEnd(e)} onClick={(e)=>{itemClicked(e)}} />

      <mesh geometry={nodes.ptr_00.geometry} material={materials.glassBlue} scale={0.9} />
      <mesh geometry={nodes.ptr_01.geometry} material={materials.glassBlue} position={[0.84, 0.48, 0]} rotation={[0, 0, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_02.geometry} material={materials.glassBlue} position={[0.42, 0.48, -0.72]} rotation={[0, Math.PI / 3, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_03.geometry} material={materials.glassBlue} position={[-0.42, 0.48, -0.72]} rotation={[Math.PI, Math.PI / 3, 2.09]} />
      <mesh geometry={nodes.ptr_04.geometry} material={materials.glassBlue} position={[-0.84, 0.48, 0]} rotation={[Math.PI, 0, 2.09]} />
      <mesh geometry={nodes.ptr_05.geometry} material={materials.glassBlue} position={[-0.42, 0.48, 0.72]} rotation={[Math.PI, -Math.PI / 3, 2.09]} />
      <mesh geometry={nodes.ptr_06.geometry} material={materials.glassBlue} position={[0.42, 0.48, 0.72]} rotation={[0, -Math.PI / 3, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_07.geometry} material={materials.glassRed} position={[0.84, -0.48, 0]} rotation={[-Math.PI, 0, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_08.geometry} material={materials.glassRed} position={[0.42, -0.48, -0.72]} rotation={[-Math.PI, -Math.PI / 3, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_09.geometry} material={materials.glassRed} position={[-0.42, -0.48, -0.72]} rotation={[0, -Math.PI / 3, 2.09]} />
      <mesh geometry={nodes.ptr_10.geometry} material={materials.glassRed} position={[-0.84, -0.48, 0]} rotation={[0, 0, 2.09]} />
      <mesh geometry={nodes.ptr_11.geometry} material={materials.glassRed} position={[-0.42, -0.48, 0.72]} rotation={[0, Math.PI / 3, 2.09]} />
      <mesh geometry={nodes.ptr_12.geometry} material={materials.glassRed} position={[0.42, -0.48, 0.72]} rotation={[-Math.PI, Math.PI / 3, -Math.PI / 3]} />
      <mesh geometry={nodes.ptr_13.geometry} material={materials.glassRed} rotation={[0, 0, Math.PI]} scale={0.9} />
      <mesh geometry={nodes.ptr_earth.geometry} material={materials.earth} scale={1.05} />

      {/* <mesh geometry={nodes.card001.geometry} material={nodes.card001.material} position={[0.33, 0, -0.5]} rotation={[0, Math.PI / 2, 0]} visible={'false'}/>
      <mesh geometry={nodes.card002.geometry} material={nodes.card002.material} position={[0.33, 0, 0.5]} rotation={[0, Math.PI / 2, 0]} visible={'false'}/>
      <mesh geometry={nodes.card003.geometry} material={nodes.card003.material} position={[-0.33, 0, -0.5]} rotation={[0, Math.PI / 2, 0]} visible={'false'}/>
      <mesh geometry={nodes.card004.geometry} material={nodes.card004.material} position={[-0.33, 0, 0.5]} rotation={[0, Math.PI / 2, 0]} visible={'false'}/> */}
    </group>
  )
}

useGLTF.preload('assets/people/assets/sphere_v6.gltf')
