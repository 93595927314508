import React from 'react';
import {useEffect,useState, useRef,createRef} from "react";
import './experimentalGrid.css';
// import 'react-html5video/dist/styles.css';
import './videoGalleryPlayer.css'
import { Grid, Box} from '@mui/material';
import Item from '@mui/material/Grid';


import getVideos from '../serviceVideoGallery.js';
import getExperimentalThumbs from '../serviceExperimentalGridThumbs.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, pink, purple } from '@mui/material/colors';
import { NavLink } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Experiment_01 from '../experiments/Experiment_01';
import Experiment_02 from '../experiments/Experiment_02';
import Experiment_03 from '../experiments/Experiment_03';
import Experiment_04 from '../experiments/Experiment_04';
import Experiment_05 from '../experiments/Experiment_05';
import Experiment_06 from '../experiments/Experiment_06';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#c7054f',
    },
  },
});

const ExperimentalGrid = (props) => {
  useEffect(() => {
    console.log("Experimental GRID Loaded, msg from useEffect");
    // setvcounter(vidoes.length);
    // const vidColumns = getComputedStyle(document.documentElement).getPropertyValue('--numColumns');        // console.log("hi");
    // console.log("Video Grid Columns: ", vidColumns);
    const numColumns = Number(props.columns);
    document.documentElement.style.setProperty('--numColumns', `${numColumns}`)
    document.documentElement.style.setProperty('--vidColumns', `${numColumns}`)

    }, []);

       
    const matches = useMediaQuery('(min-width:600px)'); // returns true if screen width is 600px or more
    const [model, setModel] = useState(false);
    const thumbs = getExperimentalThumbs();
    const [tempImgSrc, setTempImgSrc] = useState('');

    console.log("=========================");
    console.log("AFTER MAIN VARS DEFINED..");
    console.log("=========================");
    
    const getImg = (imgSrc) => {
      console.warn(imgSrc);
      // navigate to route
      <Navigate to="/experimental/01" />
      setTempImgSrc(imgSrc);
      setModel(true);
    }
    
    
    return (
        <>
        {/* <h1 style={{textAlign:'center'}}>WORK SAMPLES</h1> */}
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '20vh',
            minWidth: '200px',
          }}>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <Item><h1 style={{textAlign:'center'}}>ThreeJs Lab (Experimentation)</h1></Item>
            </Grid>        
          </Grid>
      </Box>
      </ThemeProvider>

      <div id="moodboards" className='gallery'>
          {thumbs.map((each,index)=> {
            // console.log(each.src);
            return (
              <Link to={each.link} key={index}>
                    <img src={each.poster} alt="description" style={{width:'100%'}} />
                    <div className="desc">{each.detail}</div>
                  </Link>
              )
            })}
      </div>
        
        {/* <Experiment_01 /> */}
        {/* <Experiment_02 /> */}
        {/* <Experiment_03 /> */}
        {/* <Experiment_04 /> */}
        
        
        </>
    )

}

// export default Gallery
export default ExperimentalGrid;