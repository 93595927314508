
import React from "react";
import styled from "styled-components";
import { MeshStandardMaterial } from "three";
import { useRef, useState, useEffect, Suspense, useCallback } from 'react'
import { Canvas, useFrame, useThree, extend, useLoader } from '@react-three/fiber'
// import { Canvas, useFrame, useThree,  extend, useLoader } from 'react-three-fiber'
import { SphereBufferGeometry } from 'three';
import { Html, Sphere, Plane, Cylinder } from '@react-three/drei'
import { OrbitControls, useGLTF, useHelper, PerspectiveCamera, Sky, Stats, useTexture } from "@react-three/drei";
import { ringGeometry, planeGeometry, Text } from "@react-three/drei";
import { Billboard, Environment, useEnvironment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { easing, geometry } from 'maath'
import { FaMapMarkerAlt } from 'react-icons/fa'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { Grid, ButtonGroup, Button, Typography, Slider, Slide } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGLTFLoader } from '@react-three/drei';

import { useSpring } from "@react-spring/core"
import { useControls, Leva, button, buttonGroup } from 'leva'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'
import { a } from "@react-spring/web"
import { BoxHelper, SpotLightHelper, PointLightHelper, DirectionalLightHelper, DirectionalLightShadow, DirectionalLight } from "three"
import { CameraHelper } from 'three'
import HondaCivic from './Honda_Civic';
import ApartmentBuilding from "./ApartmentBuilding";
import ApartmentBuilding_v2 from "./ApartmentBuilding_v2";
import { Tween } from '@tweenjs/tween.js';
import { ambientLight } from '@react-three/drei';
import { DemoPCD } from '../components/PointCloud';
import LowPolyTree from "../components/lp_tree";
import ErrorBoundary from "../components/ErrorBoundary";
import { useProgress } from "@react-three/drei";
import './experiments.css';

const Loading = <Html><div>LOADING...</div></Html>;

function Loading3() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  useEffect(() => {
    console.log('loading..', active, progress)
  }, [active, progress])
  return (
    <>
    {Math.floor(progress)} % loaded
    </>
    )
}
// Let's make the marker into a component so that we can abstract some shared logic
// function Marker({ children, ...props }) {
  function Marker({ children, position, scale, onClick, ...props }) {

  const ref = useRef()
  // This holds the local occluded state
  // const [rotation, setRotation] = useState([0, 0, 0]);
  const [isOccluded, setOccluded] = useState()
  const [isInRange, setInRange] = useState()
  const isVisible = isInRange && !isOccluded
  // Test distance
  const vec = new THREE.Vector3()
  // useFrame((state) => {
  //   const range = state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 100
  //   if (range !== isInRange) setInRange(range);

  // })

  useFrame((state) => {
    const range = state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 100;
    if (range !== isInRange) setInRange(range);
  
    // Set the object's up direction to the global Y axis
    ref.current.up.set(0, 1, 0);
  
    // Make the marker face the camera
    ref.current.lookAt(state.camera.position);
  });

  return (
    <group ref={ref}  position={position} scale={scale}>
      <Html
// 3D-transform contents
        transform
// Hide contents "behind" other meshes
        occlude
// Tells us when contents are occluded (or not)
        onOcclude={setOccluded}
// We just interpolate the visible state into css opacity and transforms
        style={{ transition: 'all 0.2s', opacity: isVisible ? 1 : 0, transform: `scale(${isVisible ? 1 : 0.25})` }}
      {...props}>
        {children}
      </Html>
    </group>
  )
}

const Background = props => {

  const { gl } = useThree();

  const texture = useTexture("./assets/pano2.webp")
  // texture.encoding = THREE.sRGBEncoding;
  const formatted = new THREE.WebGLCubeRenderTarget(texture.image.height).fromEquirectangularTexture(gl, texture)
  return (
    <primitive attach="background" object={formatted.texture} />
  )
}

const Environment2 = props => {
  const { scene } = useThree()
  const texture = useLoader(THREE.TextureLoader, './assets/pano2.webp')
  texture.mapping = THREE.EquirectangularReflectionMapping
  scene.background = texture
  return null
}

const Lights = props => {
  const parent = useRef()
  const ambient = useRef()
  const ref = useRef()
  const cyl = useRef()
  const sky = useRef()
  const helperRef = useRef()
  // console.log("helperRef:", helperRef);
  useHelper(helperRef, CameraHelper)
  const radius = 60;
  const [sun, debugSun] = useState(false);
  const [brightness, setBrightness] = useState(1);

  const degToRad = (deg) => deg * Math.PI / 180;

  const updateLightPosition = useCallback(() => {
    const x = radius * Math.cos(props.lat * (Math.PI));//* Math.cos(long);
    const y = radius * Math.sin(props.lat * (Math.PI));//* Math.sin(degToRad(long));
    const z = 0;//radius; // * Math.sin(degToRad(lat));
    // check if debug_Sun is on
    ref.current.position.set(x, y, z);
    cyl.current.position.set(x, y, z);
    cyl.current.lookAt(0, 0, 0);
    cyl.current.rotateX(Math.PI / 2);
    // cyl.current.material = 0;

    parent.current.rotation.y = degToRad(props.long * 360);
    // move sun back on local axis
    cyl.current.translateY(-50);
    ref.current.intensity = Math.sin(props.lat * (Math.PI));
    console.log("SUN INTENSITY:", ref.current.intensity);
    const inclination = (Math.PI / 4) * (props.lat);

    console.log("inclination:", inclination);
    sky.current.inclination = inclination;

    const azimuth = props.long * 10;
    console.log("azimuth:", azimuth);
    sky.current.azimuth = azimuth;

  }, [props.lat, props.long]);
  const updateSkyBrightness = useCallback(() => {
    const turbidity = 10;
    const rayleigh = 2;
    const mieCoefficient = 0.005;
    const mieDirectionalG = 0.8;
    sky.current.material.uniforms.turbidity.value = turbidity;
    sky.current.material.uniforms.rayleigh.value = rayleigh;
    sky.current.material.uniforms.mieCoefficient.value = mieCoefficient;
    sky.current.material.uniforms.mieDirectionalG.value = mieDirectionalG;
    sky.current.material.uniforms.brightness.value = brightness;
  }, [brightness]);


  useEffect(() => {
    updateLightPosition();
    // updateSkyBrightness();
    debugSun(props.sun);
  }, [props.lat, props.long, props.sun]);
  // }, [updateLightPosition]);
  // }, [updateLightPosition, updateSkyBrightness]);

  const data = useControls("SunSky", {


    // near: { value: 1, min: 1, max: 50, step: 0.1,
    //   //hidden: (values) => !values.Debug_Sun,
    //   onChange: (v) => {
    //     ref.current.shadow.camera.near = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // },
    // far: {value: 120, min: 1, max: 150, step: 0.1,
    //   onChange: (v) => {
    //     ref.current.shadow.camera.far = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // },
    // left: { value: -24, min: -30, max: 30, step: 0.1,
    //   onChange: (v) => {
    //     ref.current.shadow.camera.left = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // },
    // right: { value: 24, min: -30, max: 30, step: 0.1,
    //   onChange: (v) => {
    //     ref.current.shadow.camera.right = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // },
    // top: { value: 45, min: -60, max: 60, step: 0.1,
    //   onChange: (v) => {
    //     ref.current.shadow.camera.top = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // },
    // bottom: { value: -20, min: -60, max: 60, step: 0.1, 
    //   onChange: (v) => {
    //     ref.current.shadow.camera.bottom = v
    //     ref.current.shadow.camera.updateProjectionMatrix()
    //   }
    // }
  })

  return (
    <>
      <Sky ref={sky} distance={25000} sunPosition={data.position} inclination={(Math.PI / 2) * (props.lat)} azimuth={-(Math.PI / 3) * (props.long + .5)} />
      <ambientLight intensity={Math.sin(props.lat * (Math.PI)) / 2} />
      <Sphere ref={parent} args={[.2, 8, 8]} position={[0, 0, 0]} castShadow >
        <meshBasicMaterial attach="material" color="yellow" />
        {debugSun ?
          <Cylinder ref={cyl} args={[1, 5, 10, 32]} position={[0, 50, 10]} rotation={[90, 0, 0]} castShadow={false} >
            <meshStandardMaterial attach="material" color="yellow" emissive="red" />
          </Cylinder> : null}
        <directionalLight
          ref={ref}
          castShadow
          shadow-bias={-0.003}
          shadow-mapSize={[1024, 1024]}
          intensity={Math.sin(props.lat * (Math.PI))}
          color={0xffffff}
          shadow-camera-top={50} //</Sphere>{data.top}
          shadow-camera-bottom={-30} //{data.bottom}
          shadow-camera-left={-30} //{data.left}
          shadow-camera-right={30} //{data.right}
          shadow-camera-near={1} //{data.near}
          shadow-camera-far={150} //{data.far}
        >
          {/* <orthographicCamera ref={helperRef} attach="shadow-camera" />   */}
        </directionalLight>

      </Sphere>


    </>
  )
}


function Experiment_05() {

  const oc = useRef();
  const [autorotate, setAutoRotate] = useState(true);
  const [showbg, setShowBG] = useState(false);
  const [degraded, degrade] = useState(false); 
  const [lat, setlat] = useState(.35);
  const [long, setlong] = useState(.65);
  const [sun, debugSun] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const rgbeTexture = useEnvironment({ files: 'assets/pano1.hdr', envMapIntensity: .2 });
  // rgbeTexture.encoding = THREE.sRGBEncoding;

  useEffect(() => {
    if (rgbeTexture) {
      setIsLoaded(true);
    }
  }, [rgbeTexture]);

  const toggleAutoRotate = () => {
    setAutoRotate((prev) => !prev);
    oc.autoRotate = !oc.autoRotate;
  };

  

  function cartesianToSpherical(origin, point) {
    const distance = Math.sqrt(
      Math.pow(point.x - origin.x, 2) +
      Math.pow(point.y - origin.y, 2) +
      Math.pow(point.z - origin.z, 2)
    );
    const polarAngle = Math.acos((point.z - origin.z) / distance);
    const azimuthAngle = Math.atan2(point.y - origin.y, point.x - origin.x);
    return { polarAngle, azimuthAngle };
  }


  function markerClicked2(position) {
    const newPos = new THREE.Vector3(position[0], position[1], position[2]);
    console.log("Position:", oc.current.object.position);
    console.log("Target:", oc.current.target);
    console.log("TargetPos:", position);
    //  calc polar and azimuthal angles to check
    // const { polarAngle, azimuthAngle } = cartesianToSpherical(oc.current.target, oc.current.object.position); // didn't matching oc values but will sort later (most prob camera position is not updated yet)
    const { polarAngle, azimuthAngle } = cartesianToSpherical(oc.current.target, newPos);
    console.log("CurrentPolarAngle", oc.current.getPolarAngle());
    console.log("CurrentAzimuthalAngle", oc.current.getAzimuthalAngle());
    console.log("TargetPolarAngle", polarAngle);
    console.log("TargetAzimuthalAngle", azimuthAngle);
    oc.current.setPolarAngle(polarAngle);
    oc.current.setAzimuthalAngle(Math.PI / 2 - azimuthAngle);
  }

  

  const handleLatChange = (event, newValue) => {
    setlat(newValue);
  };
  const handleLongChange = (event, newValue) => {
    setlong(newValue);
  };
  const handleSkyTypeChange = (event, v) => {
    setShowBG(v);
    degrade(v);
    console.log("ShowBG value:", v);
  }
  const handleDebugSun = (event, v) => {
    debugSun(v);
    console.log("ShowBG value:", v);
  }
  const SuspenseTrigger = () => {
    throw new Promise(() => {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    });
  };

  if (!isLoaded) {
    return null; // or return a loading spinner
  }
  return (
    <>
      <div style={{ height: "100%" }}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid item xs={12} sx={{ position: "relative", zIndex: 1 }}>
          <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ position: [-40, 10, 45] }} >
              <Suspense fallback={showLoading ? <Html center><Loading3 /></Html> : null}>
                {/* <SuspenseTrigger />    */}
                <ApartmentBuilding_v2 lat={lat} />
                <Lights lat={lat} long={long} sun={sun} />
            </Suspense>
              <Marker position={[0, 40, 1.3]} rotation={[0, 0, 0]} scale={10} onClick={markerClicked2}>
                <div style={{ position: 'absolute', fontSize: 10, letterSpacing: -0.5, left: 17.5 }}  >north</div>
                <FaMapMarkerAlt style={{ color: 'indianred' }} onClick={() => markerClicked2([0, 40, 1.3])} />
              </Marker>
              <Marker position={[-30, 20, 5]} rotation={[0, 0, 0]} scale={10} onClick={markerClicked2}>
                <div style={{ position: 'absolute', fontSize: 10, letterSpacing: -0.5, left: 17.5 }} >east</div>
                <FaMapMarkerAlt style={{ color: 'indianred' }} onClick={() => markerClicked2([-30, 20, 5])} />
              </Marker>
              <Marker position={[30, 30, 15]} rotation={[0, 0, 0]} scale={10}  onClick={markerClicked2} >
                <div style={{ position: 'absolute', fontSize: 10, letterSpacing: -0.5, left: 17.5 }}  >west</div>
                <FaMapMarkerAlt style={{ color: 'indianred' }} onClick={() => markerClicked2([30, 30, 15])} />
              </Marker>
              {/* <Environment files="assets/pano2.webp" background={data.ShowBG} /> */}
              {/* {data.ShowBG.getValue() ? <Environment files="assets/pano2.webp" /> : null} */}
              {/* <Environment files={data.ShowBG ? "assets/pano2.webp" : ""} /> */}
              {/* <Background />
              <Environment frames={degraded ? 1 : Infinity} resolution={256} background blur={1}>
              <Lightformer />
              </Environment> */}
              {/* {showbg ? <Environment files="assets/pano2.webp" /> : null}  */}

              <EffectComposer multisampling={8}>
                <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.3} />
                <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.25} />
              </EffectComposer>
              {/* <Suspense fallback={showLoading ? <Html center><Loading3 /></Html> : null}>

              
              {showbg ?
                <Environment
                  files="assets/pano1.hdr"
                  ground={{ height: 10, radius: 200, scale: 300 }}
                  encoding="THREE.RGBEEncoding" >
                  <mesh scale={150}>
                    <sphereGeometry args={[5, 64, 64]} />
                    <MeshStandardMaterial map={rgbeTexture} side={THREE.BackSide} envMapIntensity={.2} />
                  </mesh>
                </Environment>
                : null
              }
              </Suspense> */}
              {/* <LowPolyTree /> */}
              {/* <DemoPCD /> */}
              {/* {showbg ? <Environment files="assets/pano2.hdr" ground={{ height: 10, radius: 200, scale: 300 }} />: null} */}
              {/* {showbg ? <Environment files="assets/pano3.hdr" ground={{ height: 10, radius: 200, scale: 300 }} />: null} */}
              {/* {showbg ? <Environment files="assets/pano4.hdr" ground={{ height: 10, radius: 200, scale: 300 }} />: null} */}
              <OrbitControls ref={oc} autoRotate={autorotate}
                target={[0, 1, 0]} maxPolarAngle={Math.PI / 2.1}
                maxDistance={200} minDistance={10}
                enablePan={true} enableZoom={true}
              >
              </OrbitControls>
            
          </Canvas>
            {/* <Stats /> */}
        </Grid>
        <Grid item xs={3} sx={{ position: "absolute", zIndex: 1, right: "0%", justifyContent: "right" }}>
          <div style={{ backgroundColor: "#181c20", height: "100%", padding: "10px", margin: "10px" }}>
            <Typography sx={{ color: "white" }}>Environment</Typography>
            
            <FormControlLabel
              control={<Checkbox onChange={toggleAutoRotate} checked={autorotate} sx={{ color: "grey" }} />}
              label="Auto  Rotate"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleDebugSun} checked={sun}  sx={{ color: "grey" }} />}
              label="Debug Sun"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleSkyTypeChange} checked={showbg}  sx={{ color: "grey" }} />}
              label="Sky_vs_HDRI"
            />            

            <Typography>Lat</Typography>
            <Slider aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} defaultValue={0.35}  min ={0} max = {1} step ={0.01} onChange={handleLatChange}/>
            <Typography>Long</Typography>
            <Slider  aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} defaultValue={.65}   min ={0} max = {1} step ={0.05} onChange={handleLongChange}/>
            
          </div>
        </Grid>
      </Grid>

    </div >
    </>
  )
}
export default Experiment_05;