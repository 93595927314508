// import StickyFooter from './Footer';
// import Gallery from './Gallery';
// import MyCarousel from './Carousel';
// import ExperimentalGrid from './ExperimentalGrid';


import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'

// import { Suspense } from "react";
import Typography from "@mui/material/Typography";
import TimePicker from "@mui/lab/TimePicker";
import DateAdapter from "@mui/lab/AdapterDateFns";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { LocalizationProvider } from '@mui/x-date-pickers'
import { Canvas } from '@react-three/fiber'
import { useFrame } from '@react-three/fiber';
import { Grid, Html, Plane, Sphere } from '@react-three/drei'
import { ringGeometry, planeGeometry } from "@react-three/drei";
import { Environment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { OrbitControls, Stats, Text } from "@react-three/drei";
import { useSpring } from "@react-spring/core"
import { useProgress } from "@react-three/drei";
import { a } from "@react-spring/web"

import { Model } from "../experiments/Model";
// import { Pendulums } from "../experiments/Pendulums";
import { Pendulums2 } from "../experiments/Pendulums2";

// import { BeatLoader } from 'react-spinners';
import { Loading, ShowLoading, HideLoading } from 'react-loading-ui';
import useSound from 'use-sound';

// import bgMusic from '../../assets/pendulum/bg_music.mp3';


import axios from "axios";
// import './Contact.css'
const Loading2 = <Html><div>LOADING...</div></Html>;

function Loading3() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  useEffect(() => {
    console.log(active, progress)
  }, [active, progress])
  return <Html center>{Math.floor(progress)} % loaded</Html>
}


function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();
  applyProps(refMat, {color: props.color});
  

  return (
    <group position={[0, .1, -10]} scale={3}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial ref={refMat}
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={90}
          metalness={.2}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
        />
      </mesh>
    </group>
  
   )
}
export function Experiment_02() {
  const bgMusic = `${process.env.PUBLIC_URL}/assets/pendulum/bg_music.mp3`;
  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  // const [play, { stop, isPlaying }] = useSound(bgMusic, { volume: 1, loop: true });
  const [play, { sound }] = useSound(bgMusic, { volume: 1, autoplay: true });

  // light stuff
  const lightRef1= useRef();
  const lightRef2= useRef();
  const analyserRef = useRef();
  const audioRef = useRef();
  const clock = useRef(new THREE.Clock());


  const handleLoadingComplete = () => {
    console.log("Loading Complete");
    setIsLoading(false);
    setShowLoading(false);
    HideLoading();
  };
    const [toggle, set] = useState(0)

    const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

    const [myText, setMyText] = useState("Hello World");
    const [bgColor, setBgColor] = useState("#eee");

    const adjustBackground = (color) => {
      console.log("adjusting background color to " + color)
      setBgColor(color);
      // oc.background = new THREE.Color(color);
    };
    const oc = useRef();
    // useEffect(() => {
    //   // play()
     

     
    // }, [play]);

    useEffect(() => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Change this to a higher value for more precision

    const audioElement = new Audio(`${process.env.PUBLIC_URL}/assets/pendulum/bg_music.mp3`);
    audioElement.loop = true;
    audioElement.volume = 1;
    audioElement.autoplay = true;

    const source = audioContext.createMediaElementSource(audioElement);
    source.connect(analyser);
    analyser.connect(audioContext.destination);

    analyserRef.current = analyser;
    audioRef.current = audioElement;
  }, []);

    // useFrame(() => {
    //   if (analyserRef.current) {
    //     const data = new Uint8Array(analyserRef.current.frequencyBinCount);
    //     analyserRef.current.getByteFrequencyData(data);
  
    //     // Calculate the average value
    //     const average = data.reduce((sum, value) => sum + value, 0) / data.length;
  
    //     // Scale the average value to a range suitable for light intensity
    //     const intensity = average / 255;
  
    //     // Update the light intensity
    //     if (lightRef1.current) {
    //       lightRef1.current.intensity = intensity;
    //     }
    //   }
    // });
    
 
    
   
    
    return (
        <>
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ fov: 45, position: [20, 5, 15] }}>
        <color attach="background" args={['#000']} />
        
        {/* <Suspense fallback={null}>  */}
        {/* <Suspense fallback={ Loading }>  */}
        {/* <Suspense fallback={<BeatLoader color="blue" loading={true} size={15} />}> */}
        {/* <Suspense fallback={<Loading loading={isLoading} />}> */}
        {/* <Suspense fallback={showLoading ? <Loading loading={isLoading} /> : null}> */}
        <Suspense fallback={showLoading ? <Loading3 /> : null}>

        <hemisphereLight intensity={0.25} />
        <ambientLight intensity={0.5} />
        <spotLight ref={lightRef1} position={[-30, 10, 0]} angle={0.45} penumbra={1} intensity={10} color="orange" lookAt={[-10,0,0]}/>
        <spotLight ref={lightRef1} position={[30, 10, -40]} angle={0.45} penumbra={1} intensity={15} color="skyblue" lookAt={[15,0,0]}/>
        <Ground2 color={bgColor}/>
        {/* <Pendulums /> */}
        {/* <Pendulums onLoadingComplete={handleLoadingComplete} /> */}
        <Pendulums2 onLoadingComplete={handleLoadingComplete}/>


        
        <Environment resolution={2048}>

          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />

          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />

          <Lightformer form="ring" color="blue" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
       

        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
          <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
        </EffectComposer> 
      <OrbitControls ref={oc} target={[4,6,-10]} autoRotate={true}/>
      <Stats/>
      </Suspense>
        </Canvas>
        
        {/* <StickyFooter /> */}
        </>
    )
}
export default Experiment_02;