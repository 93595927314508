import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_06 from '../experiments/Experiment_06';
import Experiment_07 from '../experiments/Experiment_07';

export function Experiment_p07() {
    return (
        <>
            <Experiment_07 />
        </>
    );
}
export default Experiment_p07;