import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_06 from '../experiments/Experiment_06';

export function Experiment_p06() {
    return (
        <>
            <Experiment_06 />
        </>
    );
}
export default Experiment_p06;