
import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, Sphere } from '@react-three/drei'
import { OrbitControls, Stats, useTexture } from "@react-three/drei";
import { ringGeometry, planeGeometry, Text } from "@react-three/drei";
import { Environment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { Grid, ButtonGroup, Button, Typography, Slider, Fade } from '@mui/material';
// import { superman } from "./Honda_Civic";
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Snackbar, SnackbarContent, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useProgress } from "@react-three/drei";

import { useSpring } from "@react-spring/core"
import { useControls, Leva, button, buttonGroup } from 'leva'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'
import clientLogo from '../assets/coolite/logo_header.png';
import { a } from "@react-spring/web"

import HondaCivic from './Honda_Civic';
import Corolla from "./Corolla";
import Corolla2 from "./Corolla2";
import getCooliteItems from "../service_coolite.js";
import getVideos from '../serviceVideoGallery.js';
// import { useMediaQuery } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// import { useMediaQuery } from "react-responsive";

import { useTheme } from '@mui/material/styles';
import './experiments.css'


const Loading = <Html><div>LOADING...</div></Html>;


function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();

  const [baseColor, setBaseColor] = useState(props.color);
  const [rough, setRough] = useState(1.0);
  const [metal, setMetal] = useState(1.0);
  const [mixstrength, setMixstrength] = useState(5);


  useEffect(() => {

    var r = parseInt(props.color.slice(1, 3), 16);
    var g = parseInt(props.color.slice(3, 5), 16);
    var b = parseInt(props.color.slice(5, 7), 16);
    var v = Math.max(r, g, b);
    setRough(1 - (v / 255));
    setMixstrength(rough * 5);
    setMetal(v / 255);
    console.log("mixStrength: " + rough);
    console.log("metal: " + metal);

    setBaseColor(props.color);
    applyProps(refMat, { color: props.color, mixStrength: mixstrength });
  }, [props.color, rough, metal]);


  return (
    <group position={[0, -.01, 0]}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          ref={refMat}
          color={baseColor}
      />
      </mesh>
    </group>

  )
}
function Loading3() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  useEffect(() => {
    console.log(active, progress)
  }, [active, progress])
  return (
    <>
      {Math.floor(progress)} % loaded
    </>
  )
}

function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}

export function Experiment_07() {


  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery('(max-width:1100px)');

  const buttonStyle = {
    height: isMobile ? '15px' : '20px',
    width: isMobile ? '15px' : '20px',
  };
  const typographyStyle = {
    fontSize: isMobile ? '0.8rem' : '1rem',
  };
  const snackbarContainerStyle = {
    position: 'absolute',
    backgroundColor: 'transparent',
    boxshadow: 'none',
    top: isMobile ? '50px' : '100px',
    left: isMobile ? '25px' : '50px',
    minWidth: isMobile ? '180px' : '400px',
    maxWidth: isMobile ? '200px' : '600px',


    // '& img': {
    //   // Styles for the img tag
    //   maxWidth: isMobile ? '150px' : '280px',
    //   width: '100%',
    //   height: 'auto',
    // },

    // '& p': {

    //   fontSize: isMobile ? 'small' : 'large',
    // },



  };
  const logoStyle = {
    position: 'absolute',
    top: isMobile ? '5px' : '50px',
    left: isMobile ? '5px' : '50px',
    // maxWidth: isMobile ? '150px' : '350px',
    width: isMobile ? '100px' : '300px',
    zIndex: 1,
  };
  console.log("MOBILE DEVICE:", isMobile); // Check the value of isMobile in the console
  window.onkeydown = function(event) {
    if (event.key === 'Tab') {
      event.preventDefault(); // prevent the default action (scroll / move caret)
      setPanelVisible(!isPanelVisible); // toggle the visibility
    }
  };
  const oc = useRef();
  const [isPanelVisible, setPanelVisible] = useState(true);
  const [parentState, setParentState] = useState("initial state");
  const [activeColor, setActiveColor] = useState("#ffffff");
  const [activeRoughness, setActiveRoughness] = useState(0.25);
  const [activeMetal, setActiveMetal] = useState(1.0)
  const [showLoading, setShowLoading] = useState(true);

  const [activeGlass1, setActiveGlass1] = useState(0.25);
  const [activeGlass2, setActiveGlass2] = useState(0.25);
  const [activeGlass3, setActiveGlass3] = useState(0.25);

  const [hex, setHex] = useState("#fff");
  const [toggle, setToggle] = useState(true);
  const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])
  const [bgColor, setBgColor] = useState("#888888");
  const [myText, setMyText] = useState("Hello World");
  const cooliteItems = getCooliteItems();
  const [expanded, setExpanded] = useState('panel1');

  const [itemdata, setItemdata] = useState({
    "img": "https://www.coolite.com.au/wp-content/uploads/2019/10/coolite-logo.png",
    "Visible Light Transmittance": 0,
    "Visible Light Reflected": 0,
    "Ultraviolet Rejected": 0,
    "Solar Energy Rejected": 0
  });


  const r1 = useRef();
  const r2 = useRef();
  const r3 = useRef();
  const rings = [r1, r2, r3];
  const [autorotate, setAutoRotate] = useState(false);
  const [postProcessing, setPostProcessing] = useState(false);
  const toggleAutoRotate = () => {
    setAutoRotate((prev) => !prev);
    oc.autoRotate = !oc.autoRotate;
  };
  const togglePostProcessing = () => {
    setPostProcessing((prev) => !prev);
  };
  const onChangeShcema = schemaID => {
    console.log("here");
  };
  const updateColor = (color) => {
    console.log("Updating Color", color);
    setActiveColor(color);
  }
  const updateBgColor = (color) => {
    console.log("Updating BgColor", color);
    setBgColor(color);
  }
  const updateRoughness = (event, roughness) => {
    console.log(roughness);
    setActiveRoughness(roughness);
  };
  const updateMetal = (event, metal) => {
    setActiveMetal(metal)
  }
  const handleAccordianClick = (panel) => (event, newExpanded) => {
    if (panel === "panel1") {
      markerClicked2([3,1,1.5]);
    } else if (panel === "panel2") {
      markerClicked2([1,3,2]); // third value is world up vector
    } else if (panel === "panel3") {
      markerClicked2([2,-5,3]);
    }
    setExpanded(newExpanded ? panel : false);
  };

  const handleItemClick = (category, index) => {
    setOpen(true);  // Open the Snackbar
    var glassValue = 0.0;
    if (index == 0) {
      glassValue = 0.80;
    } else if (index == 1) {
      glassValue = 0.65;
    } else if (index == 2) {
      glassValue = 0.5;
    } else if (index == 3) {
      glassValue = 0.35;
    }

    if (category === "panel1") {
      setActiveGlass1(glassValue);
      // oc.current.target = new THREE.Vector3(0,1,0);
      // markerClicked2([5,1,1]);

    } else if (category === "panel2") {
      setActiveGlass2(glassValue);
      // oc.current.target = new THREE.Vector3(0,1,2);
      // markerClicked2([3,3,1]);
    } else if (category === "panel3") {
      setActiveGlass3(glassValue);
      // oc.current.target = new THREE.Vector3(0,1,-2);
      // markerClicked2([2,-5,1]);
    }
    // now 2d anim part
    // get coolite item by index
    const cooliteItem = cooliteItems[index];
    var data = cooliteItem.data;
    setItemdata({
      "img": cooliteItem.poster,
      // "Visible Light Transmittance": data['vlt'],
      // "Visible Light Reflected": data['vlr'],
      // "Ultraviolet Rejected": data['uva/uvb'],
      // "Solar Energy Rejected": data['ts']
    });
    


  };
  function markerClicked2(position) {
    const newPos = new THREE.Vector3(position[0], position[1], position[2]);
    console.log("Position:", oc.current.object.position);
    console.log("Target:", oc.current.target);
    console.log("TargetPos:", position);
    //  calc polar and azimuthal angles to check
    // const { polarAngle, azimuthAngle } = cartesianToSpherical(oc.current.target, oc.current.object.position); // didn't matching oc values but will sort later (most prob camera position is not updated yet)
    const { polarAngle, azimuthAngle } = cartesianToSpherical(oc.current.target, newPos);
    console.log("CurrentPolarAngle", oc.current.getPolarAngle());
    console.log("CurrentAzimuthalAngle", oc.current.getAzimuthalAngle());
    console.log("TargetPolarAngle", polarAngle);
    console.log("TargetAzimuthalAngle", azimuthAngle);
    oc.current.setPolarAngle(polarAngle);
    oc.current.setAzimuthalAngle(Math.PI / 2 - azimuthAngle);
  }
  // moving camera target (approach 01)
  const focuschange = (e, opt) => {
    console.log(e.target);
    console.log(e.target.value);


    // console.log(oc); // current orbit control
    // console.log(oc.current); // OrbitControls
    console.log(oc.current.target); // OrbitControls target object (to look at vector 3)
    if (opt === '1') {
      //   oc.current.target = s1.current.position;
    }
  }
  function cartesianToSpherical(origin, point) {
    const distance = Math.sqrt(
      Math.pow(point.x - origin.x, 2) +
      Math.pow(point.y - origin.y, 2) +
      Math.pow(point.z - origin.z, 2)
    );
    const polarAngle = Math.acos((point.z - origin.z) / distance);
    const azimuthAngle = Math.atan2(point.y - origin.y, point.x - origin.x);
    return { polarAngle, azimuthAngle };
  }



  const randomizeRingGeoPositions = () => {
    console.log("randomizing ring geo positions")
    // iterate through the rings and set their positions to random values
    for (let i = 0; i < rings.length; i++) {
      if (rings[i].current) {
        rings[i].current.position.x = Math.random() * 10 - 5;
        rings[i].current.position.z = Math.random() * 10 - 5;
        rings[i].current.position.y = Math.random() * 0.2;
        var ringeo = rings[i].current.geometry;
        console.log(ringeo.parameters.innerRadius);
        rings[i].current.geometry.parameters.innerRadius = Math.random() * 2;
        rings[i].current.geometry.parameters.outerRadius = (Math.random() * 2.1) + rings[i].current.geometry.parameters.innerRadius;
        rings[i].current.geometry.parameters.thetaSegments = Math.floor(Math.random() * 7) + 3;
        rings[i].current.geometry.parameters.phiSegments = Math.floor(Math.random() * 7) + 3;
        rings[i].current.geometry.parameters.thetaStart = Math.random() * 2 * Math.PI;
        rings[i].current.geometry.parameters.thetaLength = Math.random() * 2 * Math.PI;
        rings[i].current.material.color = new THREE.Color(Math.random(), Math.random(), Math.random());
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // good, it tells me about mobile device
  useEffect(() => {
    console.log("MOBILE DEVICE:", isMobile);
  }, [isMobile]);

  useEffect(() => {
    const handleWheel = (event) => {
      console.log("Distance:", oc.current.object.position.distanceTo(oc.current.target));    };
  
    window.addEventListener('wheel', handleWheel);
  
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const [lazyness, setLazyness] = useState(0.5);
  const [rotSpeed, setRotSpeed] = useState(1.0);
  function c_lazyness(value) {
    // oc.current.enableDamping = true;
    oc.current.dampingFactor = value;
  }
  function c_rotSpeed(value) {
    oc.current.rotateSpeed = value;
  }

  return (
    <>
      <div style={{ height: "100%" }}>
        <div className="container " id="stackBarContainer" style={snackbarContainerStyle}>
        {/* backgroundColor: 'transparent' */}
          <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            style={{ opacity: 1.0, position: 'fixed', top: '10px', left: '10px',  }}
            onClick={handleClose}
          >
            <SnackbarContent
              message={
                <div >
                  <img src={itemdata["img"]} alt="coolite"
                    style={{
                      maxWidth: isMobile ? '200px' : '500px',
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                  {/* <p style={{ fontSize: isMobile ? 'small' : 'large', }}>Visible Light Transmittance: {itemdata["Visible Light Transmittance"]}</p>
                  <p style={{ fontSize: isMobile ? 'small' : 'large', }}>Visible Light Reflected: {itemdata["Visible Light Reflected"]}</p>
                  <p style={{ fontSize: isMobile ? 'small' : 'large', }}>Ultraviolet Rejected: {itemdata["Ultraviolet Rejected"]}</p>
                  <p style={{ fontSize: isMobile ? 'small' : 'large', }}>Solar Energy Rejected: {itemdata["Solar Energy Rejected"]}</p> */}
                </div>
              }
              action={
                <IconButton size="small" aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                  {/* <CloseIcon fontSize="small" /> */}
                </IconButton>
              }
              style={{ minWidth: '180px', backgroundColor:'transparent', boxShadow:'none'}} // Add this line
            />
          </Snackbar>
        </div>
        <Grid container spacing={0} sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ position: "relative", zIndex: 1, height: '100vh' }}>
            <div style={{ position: 'relative', height: '100%' }}>
              <Canvas style={{ display: 'flex', width: "100%", height: "100%" }} camera={{ position: [8, 2, 8], fov: 45 }}>
                <color attach="background" args={[bgColor]} />
                <Suspense fallback={showLoading ? <Html center><Loading3 /></Html> : null}>
                  <hemisphereLight intensity={1} />
                  <ambientLight intensity={0.5} />
                  <spotLight position={[10, 15, 10]} angle={0.15} penumbra={1} intensity={2}  />
                  <spotLight position={[-10, 15, -10]} angle={0.15} penumbra={1} intensity={2}  />
                  <pointLight position={[-10, 5, 10]} intensity={2}/>
                  <pointLight position={[10, 5, -10]} intensity={2} />
                  {/* <ContactShadows resolution={1024} frames={1} position={[0, .01, 0]} scale={15} blur={0.5} opacity={1} far={20} /> */}
                  <Ground2 color={bgColor} />
                  {/* <mesh ref={r1} scale={4} position={[-3, 0, -1]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
                    <ringGeometry args={[0.9, .95, 3, 1]} />
                    <meshStandardMaterial color="yellow" roughness={0.75} />
                  </mesh>
                  <mesh ref={r2} scale={3} position={[3, 0, 3]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                    <ringGeometry args={[0.9, 1, 3, 3]} />
                    <meshStandardMaterial color="green" roughness={0.75} />
                  </mesh>
                  <mesh ref={r3} scale={3} position={[5, 0, -5]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                    <ringGeometry args={[1.3, 1.35, 50, 3]} />
                    <meshStandardMaterial color="red" roughness={0.75} />
                  </mesh> */}
                  {/* <Corolla2 scale={1.5} parentState={parentState} */}
                  <Corolla scale={1.5} parentState={parentState} 
                    activeColor={activeColor}
                    activeRoughness={activeRoughness}
                    activeMetal={activeMetal}
                    activeGlass1={activeGlass1}
                    activeGlass2={activeGlass2}
                    activeGlass3={activeGlass3}
                  />
                  {postProcessing ? (
                    <>
                  <Environment resolution={512}>
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
                    <Lightformer intensity={1} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
                    <Lightformer intensity={1} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
                    <Lightformer form="ring" color="blue" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
                  </Environment>
                  <EffectComposer multisampling={8}>
                    <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
                    <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
                  </EffectComposer>
                  </>
                  ) : null}
                  <OrbitControls ref={oc} target={[0, 0, 0]}
                    autoRotate={autorotate}
                    minZoom={0.5}
                    maxZoom={3}
                    minDistance={2}
                    maxDistance={8}
                    maxPolarAngle={Math.PI / 2.1}
                    dampingFactor={.7} // Increase to make rotation stop more quickly
                    rotateSpeed={1.25} // Increase to make camera rotate faster
                     />
                </Suspense>
              </Canvas>
              <img src={clientLogo} alt="description_of_image" style={logoStyle} />
            </div>
          </Grid>
          {isPanelVisible && (
          <Grid item xs={3} sx={{ position: "absolute", zIndex: 1, right: "0%", justifyContent: "right" }}>
            <div style={{ backgroundColor: "#181c20", height: "100%", padding: "10px", margin: "10px" }}>
              <Typography sx={{ color: "white" }} style={{ typographyStyle }} >Coolight Configurator</Typography>
              <ButtonGroup variant="text" aria-label="outlined primary button group" >
                <Button onClick={() => updateBgColor("#222222")} >Dark</Button>
                <Button onClick={() => updateBgColor("#444444")}>Grey</Button>
                <Button onClick={() => updateBgColor("#888888")}>Bright</Button>
              </ButtonGroup>
              <Typography sx={{ marginTop: "10px", color: "grey" }}>BodyPaint</Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group" >
                {/* <Button onClick={() => updateColor("#621D1D")} sx={{ backgroundColor: "#621D1D", height: "20px", width: "20px", '&:hover': { backgroundColor: '#621D1D' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button> */}
                <Button onClick={() => updateColor("#4e6e81")} sx={{ backgroundColor: "#4e6e81", height: "20px", width: "20px", '&:hover': { backgroundColor: '#4e6e81' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button>
                <Button onClick={() => updateColor("#002e63")} sx={{ backgroundColor: "navy", height: "20px", width: "20px", '&:hover': { backgroundColor: '#2E5A88' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button>
                {/* <Button onClick={() => updateColor("#006400")} sx={{ backgroundColor: "green", height: "20px", width: "20px", '&:hover': { backgroundColor: '	#90EE90' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button> */}
                <Button onClick={() => updateColor("#dddddd")} sx={{ backgroundColor: "white", height: "20px", width: "20px", '&:hover': { backgroundColor: '#ffffff' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button>
                <Button onClick={() => updateColor("#C0C0C0")} sx={{ backgroundColor: "silver", height: "20px", width: "20px", '&:hover': { backgroundColor: '#D8D8D8' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button>
                <Button onClick={() => updateColor("#808080")} sx={{ backgroundColor: "grey", height: "20px", width: "20px", '&:hover': { backgroundColor: '#D3D3D3' } }} style={{ minWidth: "20px", maxWidth: "40px" }}></Button>
              </ButtonGroup>
              <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianClick('panel1')} sx={{ backgroundColor: '#333' }}>
                <AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Window Tints (body)</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%', maxWidth: '250px', height: 'auto' }}>
                  {cooliteItems.map((item, index) => (
                    <Box
                    key={index}
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'left',
                      '& button': {
                        backgroundColor: '#333', // dark theme
                        color: '#fff', // white text
                        border: '1px solid #ccc', // white border
                        borderRadius: '10px', // round border
                        padding: '10px', // padding to give an extruded feel
                        cursor: 'pointer', // hand icon on hover
                        transition: 'background-color 0.05s ease-in-out', // transition for hover effect
                        '&:hover': {
                          backgroundColor: '#555', // slightly lighter on hover
                        },
                      },
                    }}
                    onClick={() => handleItemClick("panel1", index)}
                  >
                    <button>
                      {/* <img src={item.thumb} alt={item.detail} style={{ width: '100%', height: 'auto' }} /> */}
                      <Typography>{item.detail}</Typography>
                    </button>
                  </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleAccordianClick('panel2')} sx={{ backgroundColor: '#333' }}>
                <AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Front Windshield</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%', maxWidth: '250px', height: 'auto' }}>
                  {cooliteItems.map((item, index) => (
                    <Box
                    key={index}
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'left',
                      '& button': {
                        backgroundColor: '#333', // dark theme
                        color: '#fff', // white text
                        border: '1px solid #ccc', // white border
                        borderRadius: '10px', // round border
                        padding: '10px', // padding to give an extruded feel
                        cursor: 'pointer', // hand icon on hover
                        transition: 'background-color 0.05s ease-in-out', // transition for hover effect
                        '&:hover': {
                          backgroundColor: '#555', // slightly lighter on hover
                        },
                      },
                    }}
                    onClick={() => handleItemClick("panel2", index)}
                  >
                    <button>
                      {/* <img src={item.thumb} alt={item.detail} style={{ width: '100%', height: 'auto' }} /> */}
                      <Typography>{item.detail}</Typography>
                    </button>
                  </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleAccordianClick('panel3')} sx={{ backgroundColor: '#333' }}>
                <AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Back Windshield</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%', maxWidth: '250px', height: 'auto' }}>
                  {cooliteItems.map((item, index) => (
                    <Box
                    key={index}
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'left',
                      '& button': {
                        backgroundColor: '#333', // dark theme
                        color: '#fff', // white text
                        border: '1px solid #ccc', // white border
                        borderRadius: '10px', // round border
                        padding: '10px', // padding to give an extruded feel
                        cursor: 'pointer', // hand icon on hover
                        transition: 'background-color 0.05s ease-in-out', // transition for hover effect
                        '&:hover': {
                          backgroundColor: '#555', // slightly lighter on hover
                        },
                      },
                    }}
                    onClick={() => handleItemClick("panel3", index)}
                  >
                    <button>
                      {/* <img src={item.thumb} alt={item.detail} style={{ width: '100%', height: 'auto' }} /> */}
                      <Typography>{item.detail}</Typography>
                    </button>
                  </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <FormControlLabel
                control={<Checkbox onChange={toggleAutoRotate} checked={autorotate} sx={{ color: "grey" }} />}
                label="autoRotate"
              />
              {/* <FormControlLabel
                control={<Checkbox onChange={togglePostProcessing} checked={postProcessing} sx={{ color: "grey" }} />}
                label="POST PROCESSING"
              /> */}
              {/* <Typography>Controller Lag</Typography>
              <Slider defaultValue={0.5} onChange={(event, newValue) => setLazyness(newValue)} aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} min ={.25} max = {.95} step ={0.05} />
              <Typography>Rotation Speed</Typography>
              <Slider defaultValue={1} onChange={(event, newValue) => setRotSpeed(newValue)} aria-label="Default" valueLabelDisplay="auto" size = "small" sx = {{width :"250px"}} onChange={c_rotSpeed} min ={.5} max = {2} step ={0.1} /> */}
            </div>
          </Grid>
          )}
        </Grid>

      </div>

      {/* <StickyFooter /> */}
    </>
  )
}
export default Experiment_07;