import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {  DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "./styles.css";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      <Link color="inherit" href="https://rainbowninjas.co.uk">
        rainbowninjas.co.uk
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function ContactUs() {
  return (
    <Typography variant="body2" >
      {'Email: '}
      <Link color="inherit" href="mailto: hello@ainbowninjas.co.uk?subject='Work Query From ..!'&body='Just wanna find out about..'">Query For Work</Link>
      {' | '}
      <Link color="inherit" href="mailto: hello@ainbowninjas.co.uk?subject='Job Query From ..!'&body='Just wanna find out about..'">Query For Job</Link>
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '10vh',
      }}>
      {/* <CssBaseline /> */}
      {/* <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          Sticky footer
        </Typography>
        <Element name="test2" className="element">
        <Typography variant="h5" component="h2" gutterBottom>
          {'Pin a footer to the bottom of the viewport.'}
          {'The footer will move as the main element of the page grows.'}
        </Typography>
        </Element>
        <Typography variant="body1">Sticky footer placeholder.</Typography>
      </Container> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={8}>
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={8}>
          <Item>xs=8</Item>
        </Grid>
      </Grid> */}
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container >
          {/* two columns, one for copy rights and one for email */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Copyright />
            </Grid>
            <Grid item xs={4}>
               <ContactUs />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
              info[at]rainbowninjas.co.uk
            </Typography>
            </Grid>

          </Grid>
          

        </Container>
      </Box>
    </Box>
  );
}