import StickyFooter from '../components/Footer';
import Gallery from '../components/Gallery';
import MyCarousel from '../components/Carousel';
import ExperimentalGrid from '../components/ExperimentalGrid';


export function Experimental() {
    return (
        <>
            {/* <h1 style={{textAlign:'center'}}>Experimental</h1> */}
            {/* <MyCarousel /> */}
            <ExperimentalGrid columns="2"/>
            {/* <StickyFooter/> */}
        </>
    );
}
export default Experimental;

