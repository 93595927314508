/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Honda_Civic.glb -o Honda_Civic2.jsx
*/

import React, { useRef,useState, forwardRef, useImperativeHandle,useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { applyProps } from '@react-three/fiber'
import { useControls, button, Leva,buttonGroup,folder } from 'leva'



// function HondaCivic (props){
const HondaCivic= forwardRef((props, ref)=> {
  
  const [childState, setChildState] = useState(props.parentState);
  const [activeColor, setActiveColor] = useState(props.activeColor);
  const [activeRoughness,setActiveRoughness] = useState(props.activeRoughness);
  const [activeMetal,setActiveMetal] = useState(props.activeMetal);

  useEffect(() => {
    setActiveColor(props.activeColor);
    applyProps(materials.body, { color: props.activeColor });
    applyProps(materials.body, { roughness: props.activeRoughness });
    applyProps(materials.body,{metalness: props.activeMetal})
  },[props.activeColor,props.activeRoughness,props.activeMetal]);
  

  // const [myColor,setMyColor] = useRef(ref);
  function click(){
    console.log("I'm a Honda Civic, click");
  }
  const { nodes, materials } = useGLTF('assets/civic/Honda_Civic.gltf')
  
  const [carBodyColor, setCarBodyColor] = useState('#4e6e81')
  const [carBodyMetal, setCarBodyMetal] = useState(1.0)
  const [carBodyRough, setCarBodyRough] = useState(0.2)
  

  const setCarBodyPaintColor = (color) => {
      setCarBodyColor(color);
      applyProps(materials.body, { color: color , emissiveIntensity: 0});

    }
  const updateShaderRoughness = (rough) => {
    setCarBodyRough(rough);
    applyProps(materials.body, { roughness: rough, emissiveIntensity: 0});
  }
  const updateShaderMetalness = (metal) => {
    setCarBodyMetal(metal);
    applyProps(materials.body, { metal: metal, emissiveIntensity: 0});
  }

  // const bodyColors = useControls("BodyPaint",{
  //   // "RED": button(() => setCarBodyPaintColor('#8b0000'), ),
  //   // "BLUE": button(() => setCarBodyPaintColor('#4682b4'), ),
  //   // "GREEN": button(() => setCarBodyPaintColor('#006400'), ),
  //   // "ORANGE": button(() => setCarBodyPaintColor('#ff8c00'), ),
  //   // "Navy": button(() => setCarBodyPaintColor('#002e63'), ),
  //   // "GREEN": button(() => setCarBodyPaintColor('#008000'), ),
  //   // "SILVER": button(() => setCarBodyPaintColor('#C0C0C0'), ),
  //   // "GREY":   button(() => setCarBodyPaintColor('#808080'), ),
  //   // "BLACK":   button(() => setCarBodyPaintColor('#000'), ),
  //   // "WHITE":   button(() => setCarBodyPaintColor('#fff'), ),
  //   // "CAR PAINTS":"click buttons ",
  //   // " ": buttonGroup({
  //   //   "red": () => {setCarBodyPaintColor('#8b0000')},
  //   //   "blue": () => {setCarBodyPaintColor('#4682b4')},
  //   //   "grn": () => {setCarBodyPaintColor('#006400')},
  //   //   "orng": () => {setCarBodyPaintColor('#ff8c00')},
  //   //   "navy": () => {setCarBodyPaintColor('#002e63')},
  //   //   "slvr": () => {setCarBodyPaintColor('#C0C0C0')},
  //   //   "grey": () => {setCarBodyPaintColor('#808080')},
  //   //   "blk": () => {setCarBodyPaintColor('#000')},
  //   // }),
  //   Roughness: { value: 0.25, min: 0, max: 1, onChange: (v) => {updateShaderRoughness(v)} },
  //   Metal: { value: 1, min: 0, max: 1, onChange: (v) => {updateShaderMetalness(v)} },

  // })
  return (
    <group {...props} dispose={null}>
      <group ref={ref} position={[-0.01, 0.77, -0.05]}>
        <group position={[0.01, -0.08, 2.06]}>
          <mesh geometry={nodes.Mesh084.geometry} material={materials.black} />
          <mesh geometry={nodes.Mesh084_1.geometry} material={materials.black_m} />
          <mesh geometry={nodes.Mesh084_2.geometry} material={materials.headLight} />
          <mesh geometry={nodes.Mesh084_3.geometry} material={materials.gum} />
        </group>
        <mesh geometry={nodes.body_02.geometry} material={materials.body} position={[0.01, -0.5, 0.02]} />
        <mesh geometry={nodes.chrome_01.geometry} material={materials.chrome} position={[0.01, -0.23, 2.22]} />
        <group position={[0.01, 0.31, 0.74]}>
          <mesh geometry={nodes.Mesh043.geometry} material={materials.d_glass} />
          <mesh geometry={nodes.Mesh043_1.geometry} material={materials.vd_glass} />
        </group>
        <mesh geometry={nodes.d_red_01.geometry} material={materials.d_red} position={[0.01, 0.11, -2]} />
        <mesh geometry={nodes.d_red_02.geometry} material={materials.d_red} position={[0.01, 0.18, -2.09]} />
        <mesh geometry={nodes.glass_08.geometry} material={materials.glass} position={[0.01, -0.14, 1.88]} />
        <mesh geometry={nodes.glass_09.geometry} material={materials.glass} position={[0.01, 0.09, -2.11]} />
        <mesh geometry={nodes.glass_10.geometry} material={materials.glass} position={[0.01, 0.09, -2.13]} />
        <mesh geometry={nodes.glass_11.geometry} material={materials.glass} position={[0.01, 0.06, -2.03]} />
        <mesh geometry={nodes.gum_01.geometry} material={materials.gum} position={[0.01, -0.41, 2.05]} />
        <mesh geometry={nodes.o_glass_01.geometry} material={materials.o_glass} position={[0.01, -0.21, 1.69]} />
        <mesh geometry={nodes.o_glass_02.geometry} material={materials.o_glass} position={[0.01, -0.11, 1.84]} />
        <mesh geometry={nodes.o_glass_03.geometry} material={materials.o_glass} position={[0.01, -0.21, 1.72]} />
        <mesh geometry={nodes.o_glass_04.geometry} material={materials.o_glass} position={[0.01, -0.21, 1.71]} />
        <mesh geometry={nodes.o_glass_05.geometry} material={materials.o_glass} position={[0.01, -0.21, 1.71]} />
        <mesh geometry={nodes.o_glass_06.geometry} material={materials.o_glass} position={[0.01, 0.12, -2.01]} />
        <mesh geometry={nodes.orange_01.geometry} material={materials.orange} position={[0.01, -0.21, 1.69]} />
        <mesh geometry={nodes.orange_02.geometry} material={materials.orange} position={[0.01, -0.21, 1.72]} />
        <mesh geometry={nodes.orange_03.geometry} material={materials.orange} position={[0.01, -0.21, 1.71]} />
        <mesh geometry={nodes.r_glass_09.geometry} material={materials.r_glass} position={[0.01, 0.1, -1.96]} />
        <mesh geometry={nodes.r_lights_01.geometry} material={materials.r_lights} position={[0.01, 0.11, -2.07]} />
        <mesh geometry={nodes.red_01.geometry} material={materials.red} position={[0.29, -0.25, 2.21]} />
        <mesh geometry={nodes.silver_05.geometry} material={materials.silver} position={[0.01, -0.44, 2.13]} />
      </group>
      <group position={[-0.78, 0.3, 1.33]}>
        <mesh geometry={nodes.black_m_03001.geometry} material={materials.black_m} position={[-0.11, 0, 0]} />
        <mesh geometry={nodes.black_m_42.geometry} material={materials.black_m} position={[-0.12, 0, 0]} />
        <mesh geometry={nodes.black_m_53.geometry} material={materials.black_m} />
        <mesh geometry={nodes.black_m_57.geometry} material={materials.black_m} position={[-0.11, 0, 0]} />
        <mesh geometry={nodes.brakes_01.geometry} material={materials.brakes} position={[-0.04, 0, 0]} />
        <mesh geometry={nodes.brakes_amg_02.geometry} material={materials.brakes_amg} position={[-0.01, 0, 0.14]} />
        <mesh geometry={nodes.chrome_19001.geometry} material={materials.chrome} position={[-0.07, 0, 0.15]} />
        <mesh geometry={nodes.chrome_21001.geometry} material={materials.chrome} position={[-0.04, 0, 0]} />
        <mesh geometry={nodes.gum_23001.geometry} material={materials.gum} position={[-0.01, -0.06, 0.15]} />
        <mesh geometry={nodes.gum_27001.geometry} material={materials.gum} position={[0.02, 0, 0]} />
        <mesh geometry={nodes.silver_20001.geometry} material={materials.silver} position={[-0.11, -0.23, 0]} />
        <mesh geometry={nodes.silver_21001.geometry} material={materials.silver} position={[-0.13, 0, 0]} />
        <mesh geometry={nodes.silver_31.geometry} material={materials.silver} position={[-0.06, 0, 0]} />
        <mesh geometry={nodes.tire_02.geometry} material={materials.tire} />
      </group>
      <group position={[0.76, 0.3, 1.33]}>
        <mesh geometry={nodes.black_m_058.geometry} material={materials.black_m} />
        <mesh geometry={nodes.black_m_060.geometry} material={materials.black_m} position={[0.12, 0, 0]} />
        <mesh geometry={nodes.black_m_061.geometry} material={materials.black_m} position={[0.11, 0, 0]} />
        <mesh geometry={nodes.black_m_062.geometry} material={materials.black_m} position={[0.11, 0, 0]} />
        <mesh geometry={nodes.brakes_002.geometry} material={materials.brakes} position={[0.04, 0, 0]} />
        <mesh geometry={nodes.brakes_amg_003.geometry} material={materials.brakes_amg} position={[0.01, 0, -0.14]} />
        <mesh geometry={nodes.chrome_038.geometry} material={materials.chrome} position={[0.07, 0, -0.15]} />
        <mesh geometry={nodes.chrome_039.geometry} material={materials.chrome} position={[0.04, 0, 0]} />
        <mesh geometry={nodes.gum_061.geometry} material={materials.gum} position={[-0.02, 0, 0]} />
        <mesh geometry={nodes.gum_062.geometry} material={materials.gum} position={[0.01, 0.06, -0.15]} />
        <mesh geometry={nodes.silver_032.geometry} material={materials.silver} position={[0.11, 0.23, 0]} />
        <mesh geometry={nodes.silver_033.geometry} material={materials.silver} position={[0.13, 0, 0]} />
        <mesh geometry={nodes.silver_034.geometry} material={materials.silver} position={[0.06, 0, 0]} />
        <mesh geometry={nodes.tire_003.geometry} material={materials.tire} />
      </group>
      <group position={[0.77, 0.3, -1.36]}>
        <mesh geometry={nodes.black_m_063.geometry} material={materials.black_m} position={[0.11, 0, 0]} />
        <mesh geometry={nodes.black_m_064.geometry} material={materials.black_m} />
        <mesh geometry={nodes.black_m_067.geometry} material={materials.black_m} position={[0.12, 0, 0]} />
        <mesh geometry={nodes.black_m_068.geometry} material={materials.black_m} position={[0.11, 0, 0]} />
        <mesh geometry={nodes.brakes_003.geometry} material={materials.brakes} position={[0.04, 0, 0]} />
        <mesh geometry={nodes.brakes_amg_004.geometry} material={materials.brakes_amg} position={[0.01, 0, -0.14]} />
        <mesh geometry={nodes.chrome_040.geometry} material={materials.chrome} position={[0.07, 0, -0.15]} />
        <mesh geometry={nodes.chrome_041.geometry} material={materials.chrome} position={[0.04, 0, 0]} />
        <mesh geometry={nodes.gum_063.geometry} material={materials.gum} position={[-0.02, 0, 0]} />
        <mesh geometry={nodes.gum_064.geometry} material={materials.gum} position={[0.01, -0.06, -0.15]} />
        <mesh geometry={nodes.silver_035.geometry} material={materials.silver} position={[0.13, 0, 0]} />
        <mesh geometry={nodes.silver_036.geometry} material={materials.silver} position={[0.11, -0.23, 0]} />
        <mesh geometry={nodes.silver_039.geometry} material={materials.silver} position={[0.06, 0, 0]} />
        <mesh geometry={nodes.tire_004.geometry} material={materials.tire} />
      </group>
      <group position={[-0.78, 0.3, -1.36]}>
        <mesh geometry={nodes.black_m_065.geometry} material={materials.black_m} position={[-0.11, 0, 0]} />
        <mesh geometry={nodes.black_m_066.geometry} material={materials.black_m} />
        <mesh geometry={nodes.black_m_069.geometry} material={materials.black_m} position={[-0.12, 0, 0]} />
        <mesh geometry={nodes.black_m_070.geometry} material={materials.black_m} position={[-0.11, 0, 0]} />
        <mesh geometry={nodes.brakes_004.geometry} material={materials.brakes} position={[-0.04, 0, 0]} />
        <mesh geometry={nodes.brakes_amg_005.geometry} material={materials.brakes_amg} position={[-0.01, 0, 0.14]} />
        <mesh geometry={nodes.chrome_042.geometry} material={materials.chrome} position={[-0.07, 0, 0.15]} />
        <mesh geometry={nodes.chrome_043.geometry} material={materials.chrome} position={[-0.04, 0, 0]} />
        <mesh geometry={nodes.gum_065.geometry} material={materials.gum} position={[0.02, 0, 0]} />
        <mesh geometry={nodes.gum_066.geometry} material={materials.gum} position={[-0.01, 0.06, 0.15]} />
        <mesh geometry={nodes.silver_037.geometry} material={materials.silver} position={[-0.11, 0.23, 0]} />
        <mesh geometry={nodes.silver_038.geometry} material={materials.silver} position={[-0.13, 0, 0]} />
        <mesh geometry={nodes.silver_040.geometry} material={materials.silver} position={[-0.06, 0, 0]} />
        <mesh geometry={nodes.tire_005.geometry} material={materials.tire} />
      </group>
    </group>
  )
})

useGLTF.preload('assets/civic/Honda_Civic.gltf')

// export default forwardRef(HondaCivic);
export default HondaCivic;

