/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 .\lp_tree_v1.gltf -o lp_tree.jsx 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function LowPolyTree(props) {
  const { nodes, materials } = useGLTF('assets/Building/lp_tree_v1.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[-18.853, 0, 12.082]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.ip_low_poly_tree1_1.geometry} material={materials.tree} />
        <mesh geometry={nodes.ip_low_poly_tree1_2.geometry} material={materials.treeBranch} />
      </group>
    </group>
  )
}

useGLTF.preload('assets/Building/lp_tree_v1.gltf')
export default LowPolyTree;
