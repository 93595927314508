/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Corolla_2014.glb -o Corolla.js 
*/

import React, { useRef, useState, forwardRef, useImperativeHandle,useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { applyProps } from '@react-three/fiber'
import { get } from 'react-scroll/modules/mixins/scroller';
import * as THREE from 'three';
// function Corolla(props) {
const Corolla= forwardRef((props, ref)=> {


  const [childState, setChildState] = useState(props.parentState);
  const [activeColor, setActiveColor] = useState(props.activeColor);
  const [activeRoughness,setActiveRoughness] = useState(props.activeRoughness);
  const [activeMetal,setActiveMetal] = useState(props.activeMetal);
  const [activeGlass1,setActiveGlass1] = useState(props.activeGlass1);
  const [activeGlass2,setActiveGlass2] = useState(props.activeGlass2);
  const [activeGlass3,setActiveGlass3] = useState(props.activeGlass3);

  useEffect(() => {
    setActiveColor(props.activeColor);
    applyProps(materials['main_paint.001'], { color: props.activeColor });
    applyProps(materials['main_paint.001'], { roughness: props.activeRoughness });
    applyProps(materials['main_paint.001'],{metalness: props.activeMetal})
    applyProps(materials['main_paint.001'], { roughness: getRoughness(props.activeColor) });
    // set Glass material opacity to activeGlass1
    applyProps(materials.glass_windows, { opacity: props.activeGlass1 });
    applyProps(materials.glass_screen_front, { opacity: props.activeGlass2 });
    applyProps(materials.glass_screen_back, { opacity: props.activeGlass3 });
    // get new color for glass material based on opacity
    const newColor1 = getNewColor(props.activeGlass1);
  
    const newColor2 = getNewColor(props.activeGlass2);
    const newColor3 = getNewColor(props.activeGlass3);
    applyProps(materials.glass_windows, { color: newColor1 });
    applyProps(materials.glass_screen_front, { color: newColor2 });
    applyProps(materials.glass_screen_back, { color: newColor3 });

    console.log("OPACITY", materials.glass_windows.opacity);
    console.log("MAT", materials.glass_windows.color);

  },[props.activeColor,props.activeRoughness,props.activeMetal, props.activeGlass1,props.activeGlass2,props.activeGlass3]);

  // create a function which takes hex color(#aabbcc) input, convert to rgb , then calc brightness (value) of color and calculates the roughness based on brightness/luminance factor of color
  function getRoughness(hexColor){
    console.log("HexColor: " + hexColor)
    var r = parseInt(hexColor.slice(1,3),16);
    var g = parseInt(hexColor.slice(3,5),16);
    var b = parseInt(hexColor.slice(5,7),16);
    var v = Math.max(r,g,b);
    var roughness = (v/255);
    // remap roughness to 0.3 - 0.75
    roughness = roughness * 0.4 + 0.1;
    console.log("Roughness: " + roughness);
    return roughness;
  }
  function getNewColor(oldOpacity) {
    const oldMin = 0.3;
    const oldMax = 0.8;
    const newMin = 0.015;
    const newMax = 0.055;
  
    // Reverse the mapping because higher opacity should correspond to a darker color
  const newColor = ((oldMax - oldOpacity) / (oldMax - oldMin)) * (newMax - newMin) + newMin;

  return new THREE.Color(newColor, newColor, newColor);
  }
  function click(){
    console.log("I'm a Toyota Corolla, click");
  }
  const { nodes, materials } = useGLTF('assets/coolite/Corolla_2014.glb');

  // set defaults for car body
  const [carBodyColor, setCarBodyColor] = useState('#621D1D')
  const [carBodyMetal, setCarBodyMetal] = useState(1.0)
  const [carBodyRough, setCarBodyRough] = useState(0.2)

  
  
  // meant to be controlled by LEVA UI
  const setCarBodyPaintColor = (color) => {
      setCarBodyColor(color);
      applyProps(materials['main_paint.001'], { color: color , emissiveIntensity: 0});

    }
  const updateShaderRoughness = (rough) => {
    setCarBodyRough(rough);
    applyProps(materials.body, { roughness: rough, emissiveIntensity: 0});
  }
  const updateShaderMetalness = (metal) => {
    setCarBodyMetal(metal);
    applyProps(materials.body, { metal: metal, emissiveIntensity: 0});
  }

  return (
    <group {...props} dispose={null}>
      <group ref={ref} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_body_car_plastic_grainy_gray_0.geometry} material={materials.car_plastic_grainy_gray} />
        <mesh geometry={nodes.corolla_e180_body_fabric_high_0.geometry} material={materials.fabric_high} />
        <mesh geometry={nodes.corolla_e180_body_invisible_dark_0.geometry} material={materials.invisible_dark} />
        <mesh geometry={nodes.corolla_e180_body_klosz_0.geometry} material={materials.headLight_bulbs} />
        <mesh geometry={nodes.corolla_e180_body_main_paint001_0.geometry} material={materials['main_paint.001']} />
        <mesh geometry={nodes.corolla_e180_body_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_body_main_paint_0001.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.corolla_e180_body_main_paint_0002.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_body_Material003_0.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.corolla_e180_body_Material005_0.geometry} material={materials['Material.005']} />
        <mesh geometry={nodes.corolla_e180_body_miror_0.geometry} material={materials.miror} />
        <mesh geometry={nodes.corolla_e180_body_plastic1_0.geometry} material={materials.plastic1} />
      </group>
      <group position={[0.359, 0.775, 0.7]} rotation={[-1.501, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_dashboard_dark_plastic_grainy_0.geometry} material={materials.dark_plastic_grainy} />
        <mesh geometry={nodes.corolla_e180_dashboard_invisible_dark_0.geometry} material={materials.invisible_dark} />
        <mesh geometry={nodes.corolla_e180_dashboard_M_dashboard001_0.geometry} material={materials['M_dashboard.001']} />
        <mesh geometry={nodes.corolla_e180_dashboard_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_dashboard_Material016_0.geometry} material={materials['Material.016']} />
        <mesh geometry={nodes.corolla_e180_dashboard_plastic1_0.geometry} material={materials.plastic1} />
        <mesh geometry={nodes.corolla_e180_dashboard_smal_screen_0.geometry} material={materials.smal_screen} />
        <mesh geometry={nodes.corolla_e180_dashboard_speaker_0.geometry} material={materials.speaker} />
      </group>
      <group position={[0.359, 0.775, 0.7]} rotation={[-1.501, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_dashboard001_M_dashboard_0.geometry} material={materials.M_dashboard} />
        <mesh geometry={nodes.corolla_e180_dashboard001_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_dashboard001_Material020_0.geometry} material={materials['Material.020']} />
        <mesh geometry={nodes.corolla_e180_dashboard001_Material021_0.geometry} material={materials['Material.021']} />
        <mesh geometry={nodes.corolla_e180_dashboard001_Material026_0.geometry} material={materials['Material.026']} />
        <mesh geometry={nodes.corolla_e180_dashboard001_screen_0.geometry} material={materials.screen} />
      </group>
      <group position={[0.454, 0.893, 0.818]} rotation={[-1.501, -0.447, 0]}>
        <mesh geometry={nodes.corolla_e180_dashboard002_dark_plastic_grainy_0.geometry} material={materials.dark_plastic_grainy} />
        <mesh geometry={nodes.corolla_e180_dashboard002_Material016_0.geometry} material={materials['Material.016']} />
      </group>
      <group position={[0.001, 1.345, 0.002]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_frontlights_klosz_0.geometry} material={materials.headLight_bulbs} />
        <mesh geometry={nodes.corolla_e180_frontlights_klosz_0001.geometry} material={materials.headLight_glass} />
        <mesh geometry={nodes.corolla_e180_frontlights_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_frontlights_Material014_0.geometry} material={materials['Material.014']} />
        <mesh geometry={nodes.corolla_e180_frontlights_plastic1_0.geometry} material={materials.plastic1} />
      </group>
      <group position={[0, 0.74, -1.082]} rotation={[-2.038, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_furniture_dark_plastic_grainy_0.geometry} material={materials.dark_plastic_grainy} />
        <mesh geometry={nodes.corolla_e180_furniture_leather_0.geometry} material={materials.leather} />
        <mesh geometry={nodes.corolla_e180_furniture_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_furniture_Material019_0.geometry} material={materials['Material.019']} />
        <mesh geometry={nodes.corolla_e180_furniture_seat_fabric_0.geometry} material={materials.seat_fabric} />
      </group>
      <group position={[0.705, 0.927, -0.166]} rotation={[-1.521, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_interior1_belts_0.geometry} material={materials.belts} />
        <mesh geometry={nodes.corolla_e180_interior1_car_plastic_grainy_gray_0.geometry} material={materials.car_plastic_grainy_gray} />
        <mesh geometry={nodes.corolla_e180_interior1_carpet002_0.geometry} material={materials['carpet.002']} />
        <mesh geometry={nodes.corolla_e180_interior1_dark_plastic_grainy_0.geometry} material={materials.dark_plastic_grainy} />
        <mesh geometry={nodes.corolla_e180_interior1_fabric_high_0.geometry} material={materials.fabric_high} />
        <mesh geometry={nodes.corolla_e180_interior1_klosz_0.geometry} material={materials.headLight_glass} />
        <mesh geometry={nodes.corolla_e180_interior1_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_interior1_miror_0.geometry} material={materials.miror} />
        <mesh geometry={nodes.corolla_e180_interior1_plastic1_0.geometry} material={materials.plastic1} />
      </group>
      <group position={[0.721, 0.915, 0.731]} rotation={[-1.46, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_interior_front_dark_plastic_grainy_0.geometry} material={materials.dark_plastic_grainy} />
        <mesh geometry={nodes.corolla_e180_interior_front_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_interior_front_speaker_0.geometry} material={materials.speaker} />
      </group>
      <group position={[0.359, 0.843, 0.535]} rotation={[-2.887, 0, -Math.PI]}>
        <mesh geometry={nodes.corolla_e180_steering_wheel_M_Steering_wheel_0.geometry} material={materials.M_Steering_wheel} />
        <mesh geometry={nodes.corolla_e180_steering_wheel_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_steering_wheel_plastic1_0.geometry} material={materials.plastic1} />
      </group>
      <group position={[0.714, 0.303, 1.253]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh geometry={nodes.corolla_e180_wheels_main_paint_0.geometry} material={materials.chrome_rim} />
        <mesh geometry={nodes.corolla_e180_wheels_Material011_0.geometry} material={materials['Material.011']} />
        <mesh geometry={nodes.corolla_e180_wheels_Material013_0.geometry} material={materials['Material.013']} />
        <mesh geometry={nodes.corolla_e180_wheels_tyre_side_0.geometry} material={materials.tyre_side} />
      </group>
      <group position={[0, 1.129, 0.768]} rotation={[-1.174, 0, 0]}>
        <mesh geometry={nodes.corolla_e180_windows_Material004_0_1.geometry} material={materials.glass_windows} />
        <mesh geometry={nodes.corolla_e180_windows_Material004_0_2.geometry} material={materials.glass_screen_front} />
        <mesh geometry={nodes.corolla_e180_windows_Material004_0_3.geometry} material={materials.glass_screen_back} />
      </group>
      <group position={[0, 0.744, -2.105]} rotation={[-1.367, 0, -Math.PI]}>
        <mesh geometry={nodes.Cube003_Material008_0.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cube003_Material010_0.geometry} material={materials.numberPlate} />
      </group>
      <group position={[0, 0.433, 2.161]} rotation={[-1.666, 0, 0]}>
        <mesh geometry={nodes.Cube002_Material008_0.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cube002_Material010_0.geometry} material={materials.numberPlate} />
      </group>
    </group>
  )
})

useGLTF.preload('assets/coolite/Corolla_2014.glb')
// export default forwardRef(Corolla);
export default Corolla;
