import React, { useState } from "react";
import { Modal, Box} from "@mui/material";
import Image from "mui-image";


function ImageModal({ src }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Image src={src} onClick={handleOpen} />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Image src={src} />
        </Box>
      </Modal>
    </>
  );
}

export default ImageModal;
