
function getExperimentalThumbs() {
    return [
    { 
        "poster": "assets/experiment1.png",
        "detail": "People Navigation", 
        "link": "/experiment01",
        "category": "threejs,navigation,lighting"
    },{ 
        "poster": "assets/experiment2.png", 
        "detail": "Swinging Pendulums", 
        "link": "/experiment02",
        "category": "threejs,navigation,lighting"
    },{ 
        "poster": "assets/experiment3.png",
        "detail": "Honda Civic", 
        "link": "/experiment03",
        "category": "threejs,navigation,lighting"
    },
    // { 
    //     "poster": "assets/experiment4.png", 
    //     "detail": "BTF Hover Car", 
    //     "link": "/experiment04",
    //     "category": "threejs,navigation,lighting"
    // },
    { 
        "poster": "assets/experiment5.png", 
        "detail": "Building", 
        "link": "/experiment05",
        "category": "threejs,navigation,lighting"
    },
    { 
        "poster": "assets/experiment6.png", 
        "detail": "Coolite HQ", 
        "link": "/experiment06",
        "category": "threejs,navigation,lighting"
    },
    { 
        "poster": "assets/experiment7.png", 
        "detail": "Coolite Normal", 
        "link": "/experiment07",
        "category": "threejs,navigation,lighting"
    }
];
}

export default getExperimentalThumbs;