//import Gallery from './Gallery';
// import Navbar from '../components/Nav';

import VfxImages from '../components/vfxImages';
import VfxGallery from '../components/vfxGallery';
import StickyFooter from '../components/Footer';
import VideoGallery from '../components/VideoGallery';
import VideoGallery2 from '../components/VideoGallery2';
import {  DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// import getVideos from '../serviceVideoGallery.js';
// import getRTVideos from '../serviceRTGallery.js';


import './Home.css';

export function Vfx() {
    // const videos = getVideos();
    return (
        <>
        <VfxImages />
        {/* <VideoGallery name="main" columns="2" />
        <Element name="RT" className="element">
        <VideoGallery2 name="RT" />
        </Element>  */}
         <VfxGallery />
        <StickyFooter />
        </>
    );
}
export default Vfx;