//import Gallery from './Gallery';
// import Navbar from '../components/Nav';

import VfxImages from '../components/vfxImages';
import VfxGallery from '../components/vfxGallery';
import StickyFooter from '../components/Footer';
import VideoGallery from '../components/VideoGallery';
import VideoGallery2 from '../components/VideoGallery2';
import EcamPage from '../components/EcamPage';
import {  DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// import getVideos from '../serviceVideoGallery.js';
// import getRTVideos from '../serviceRTGallery.js';


import './Home.css';

export function Ecam() {
    // const videos = getVideos();
    return (
        <>
        <EcamPage />
        <StickyFooter />
        </>
    );
}
export default Ecam;