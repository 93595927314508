// import logo from './logo.svg';
import React from 'react';
//import {useEffect} from "react";
import './App.css';
// import {Link,Route,Routes} from "react-router-dom";
// import { BrowserRouter } from 'react-router-dom';

import Nav from './components/Nav';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import AiVideos from './pages/AiVideos'
import Concepts from './pages/Concepts';
import Vfx from './pages/vfxPipeline';
import Ecam from './pages/Ecam';
import Contact from './pages/Contact';
import Experimental from './pages/Experimental';
import NotFound from './pages/NotFound';
import StickyFooter from './components/Footer';

import Experiment_p01 from './pages/Experiment_p01';
import Experiment_p02 from './pages/Experiment_p02';
import Experiment_p03 from './pages/Experiment_p03';
import Experiment_p04 from './pages/Experiment_p04';
import Experiment_p05 from './pages/Experiment_p05';
import Experiment_p06 from './pages/Experiment_p06';
import Experiment_p07 from './pages/Experiment_p07';



function App() {


  return (
    <>
      {/* <nav>
      <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/concepts">Concepts</Link></li>
      </ul>
    </nav> 
       
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/concepts" element={<Concepts/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes> */}
      <BrowserRouter>
        <div className="App">
          <Nav />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/footer" component={<StickyFooter />} />
          <Route path="/aivideos" element={<AiVideos />} />
          <Route path="/concepts" element={<Concepts />} />
          <Route path="/vfx" element={<Vfx />} />
          <Route path="/ecam" element={<Ecam />} />

          <Route path="/experimental" element={<Experimental />} />
          <Route path="/experiment01" element={<Experiment_p01 />} />
          <Route path="/experiment02" element={<Experiment_p02 />} />
          <Route path="/experiment03" element={<Experiment_p03 />} />
          <Route path="/experiment04" element={<Experiment_p04 />} />
          <Route path="/experiment05" element={<Experiment_p05 />} />
          <Route path="/experiment06" element={<Experiment_p06 />} />
          <Route path="/experiment07" element={<Experiment_p07 />} />

          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>



  );
}

export default App;


