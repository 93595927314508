/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 pendulums.glb -o pendulums2.js 
*/

import React, { useRef, useState,useEffect } from 'react'
import { applyProps } from '@react-three/fiber'

import { useGLTF } from '@react-three/drei'
import { useProgress } from '@react-three/drei';
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'

import { useLoader } from '@react-three/fiber'; 
import { useControls, button, Leva,buttonGroup,folder } from 'leva'



export function Pendulums2(props) {
  const { active, progress, total } = useProgress();
  const clock = useRef(new THREE.Clock());
  
  const { nodes, materials } = useGLTF('assets/pendulum/pendulums.glb')
  const group1 = useRef()
  const ref0 = useRef();const ref1 = useRef(); const ref2 = useRef();const ref3 = useRef();const ref4= useRef();const ref5 = useRef();const ref6 = useRef();const ref7 = useRef();const ref8 = useRef();const ref9 = useRef();
  const pendulums = [ref0, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9];
  const minDelay = 0.1;
  const maxDelay = 0.9;
  const duration = 20; // Duration in seconds
  

  const angle = useRef(0); // create a reference to store the current angle
  const [speed, setSpeed] = useState(0.01); // set the speed of the swing
  const [amplitude, setAmp] = useState(Math.Pi /4); // set the amplitude of the swing
  const [offset,setOffset]  = useState(.2);
  applyProps(materials.sphere, { metalness: .8, roughness: 0.35, color:'#666' }, [])
  applyProps(materials.cylinder, { color:'#333' }, [])

  materials["mymat"] = materials.sphere.clone();
  // materials["mymat"].name = "mymat";
  // applyProps(materials["mymat"], { map: texture1,}); // line causing issue
  // materials["mymat2"] = NodeToyMaterial({shaderData: shader1, uniforms: {tex1: {value: texture1}, tex2: {value: texture2}}})
  const bodyColors = useControls("Swing Controls",{
    Speed: { value: 0.03, min: 0, max: .2, onChange: (v) => {setSpeed(v)} },
    
    Amplitude: { value: .33, min: 0, max: 0.5, onChange: (v) => {setAmp(v)} },
    Delay: { value: .27, min: 0, max: 1, onChange: (v) => {setOffset(v)} },

  })
  const materialProps = useControls({
    _time: { value: 1, min: 0.5, max: 20, step: 0.1 }, // Level of transmission
    // TimeScale: { value: 1, min: 0.5, max: 20, step: 0.1 }, // Level of transmission

  })

  
  
  
  useFrame((state) => {

    const t = state.clock.getElapsedTime()

    const elapsedTime = clock.current.getElapsedTime();
    const timeDelta = Math.sin((elapsedTime % duration) / duration * Math.PI * 2) / 2 + 0.5;
    setOffset(THREE.MathUtils.lerp(minDelay, maxDelay, timeDelta));
    // console.log("Offset: ", offset);
    
    // update the angle using a sine function
    angle.current += speed;
    // materialProps._time = angle.current;
    // materialProps.TimeScale = angle.current;
    // rotate the mesh around the z-axis by the angle
    //ref.current.rotation.x = amplitude * Math.sin(angle.current);
    Object.values(pendulums).forEach((pendulum) => {
      pendulum.current.rotation.z = amplitude * Math.sin((angle.current+(pendulum.current.position.z*offset)));
    })

  })
  useEffect(() => {
    console.log("loading Pendulums");
    console.log("PROGRESS: ", progress);
    console.log("TOTAL: ", total);
    if (!active && progress >= total) {
      console.log("Sending Signals..for done loading");
      props.onLoadingComplete();
    }
  }, [active, progress, total, props]);
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.stand.geometry} material={materials.KB3D_CTS_SteelRoughGray} position={[-0.176, 4.727, -9]} />
      <group position={[0, 9, 0]} ref={ref0} >
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -2]} ref={ref1}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -4]} ref={ref2}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -6]} ref={ref3}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -8]} ref={ref4}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -10]} ref={ref5}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -12]} ref={ref6}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -14]} ref={ref7}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -16]} ref={ref8}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
      <group position={[0, 9, -18]} ref={ref9}>
        <mesh geometry={nodes.pendulum_01_msh.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.pendulum_01_msh_1.geometry} material={materials.sphere} />
      </group>
    </group>
  )
}

// useGLTF.preload('assets/pendulum/pendulums.glb')
export default Pendulums2;
