/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 sphere_v6.gltf -o sphere.jsx
*/

import React, { useRef } from 'react'
import { useState, useMemo, useEffect } from 'react'

import { applyProps } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { useControls } from 'leva'
import * as THREE from 'three'
// import { useTexture, useLoader } from '@react-three/drei'
// import { TextureLoader } from 'three'
import { useLoader } from '@react-three/fiber'; 
import { TextureLoader } from 'three/src/loaders/TextureLoader'; 


export function PeopleSphere2(props) {

  // TODO: Add metal / reflective shader control to test
  // TODO: Add hover and click controls for icons meshes with local transforms

  useEffect(() => {
      console.log('hi')
      // console.log(color.value)
    }, [])
  // const { nodes, materials } = useGLTF('assets/people/assets/sphere_v6.gltf')
  const { scene,nodes, materials } = useGLTF('assets/people/assets/sphere_v6.gltf');
  const cards = ['card001','card002','card003','card004'];
  const topHalf = ['ptr_00','ptr_01', 'ptr_02','ptr_03','ptr_04','ptr_05','ptr_06'];
  const lowerHalf = ['ptr_07','ptr_08', 'ptr_09','ptr_10','ptr_11','ptr_12','ptr_13'];
  const icons = ['icon_00','icon_01', 'icon_02','icon_03','icon_04','icon_05','icon_06','icon_07','icon_08', 'icon_09','icon_10','icon_11','icon_12','icon_13'];
  const iconTextures = ['ptr_00.png','ptr_01.png', 'ptr_02.png','ptr_03.png','ptr_04.png','ptr_05.png','ptr_06.png','ptr_07.png','ptr_08.png', 'ptr_09.png','ptr_10.png','ptr_11.png','ptr_12.png','ptr_13.png'];
  const [icon_00, icon_01, icon_02, icon_03, icon_04, icon_05, icon_06, icon_07, icon_08, icon_09, icon_10, icon_11, icon_12, icon_13] = useLoader(TextureLoader, [ 
    'assets/people/assets/tiles/ptr_00.png',
    'assets/people/assets/tiles/ptr_01.png', 
    'assets/people/assets/tiles/ptr_02.png', 
    'assets/people/assets/tiles/ptr_03.png', 
    'assets/people/assets/tiles/ptr_04.png', 
    'assets/people/assets/tiles/ptr_05.png', 
    'assets/people/assets/tiles/ptr_06.png', 
    'assets/people/assets/tiles/ptr_07.png', 
    'assets/people/assets/tiles/ptr_08.png', 
    'assets/people/assets/tiles/ptr_09.png', 
    'assets/people/assets/tiles/ptr_10.png', 
    'assets/people/assets/tiles/ptr_11.png',
    'assets/people/assets/tiles/ptr_12.png',  
    'assets/people/assets/tiles/ptr_13.png'
  ])
  icon_00.flipY = icon_01.flipY = icon_02.flipY = icon_03.flipY = icon_04.flipY = icon_05.flipY = icon_06.flipY = icon_07.flipY = icon_08.flipY = icon_09.flipY = icon_10.flipY = icon_11.flipY = icon_12.flipY = icon_13.flipY = false;
  icon_00.rotation=180;

  const [earthDiffuse,earthSpec,earthEmit,earthBump] = useLoader(TextureLoader, [
    'assets/people/assets/earth/earthmap1k.jpg',
    'assets/people/assets/earth/earthspec1k.jpg',
    'assets/people/assets/earth/earthemission1k.jpg',
    'assets/people/assets/earth/earthbump1k.jpg'
  ]);
  const [topcolor, setTopColor] = useState('#00f');
  const [bottomcolor, setBottomColor] = useState('#f00');
  // Leva controls
  const data = useControls({
    TopColor: {
      value: '#00f',
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        console.log(v)
        setTopColor(v)
      }
    },
    BottomColor: {
      value: '#f00',
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        console.log(v)
        setBottomColor(v)
      }
    },
  })

  // CONST REF INTERACTIONS
  // create a function to calculate area of circle
  const areaOfCircle = (radius) => {
    return Math.PI * radius * radius
  }
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)

  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => {
  //   //ref.current.rotation.x += delta * speed
  // })

  // earth shader setup
  materials.earth = new THREE.MeshPhongMaterial({
    map: earthDiffuse,
    specularMap: earthSpec,
    emissiveMap: earthEmit,
    bumpMap: earthBump,
    bumpScale: 0.05,
    shininess: .75,
    // specular: new THREE.Color('grey'),
  })
      

  // create 2 new materials and append to materials list
  materials.glassBlue = materials.glass.clone()
  materials.glassRed = materials.glass.clone()
  // rename the materials
  materials.glassBlue.name = 'glassBlue'
  materials.glassRed.name = 'glassRed'
  // set the color of the materials
  materials.glassBlue.color.set('#00f')
  materials.glassRed.color.set('#f00')
  applyProps(materials.glassBlue, { color: topcolor });
  applyProps(materials.glassRed, { color: bottomcolor });

    // create glass materials for icons

    // iconTextures.forEach((texture) => {
    //   const texturefile = 'assets/people/assets/tiles/'+texture
    //   // const texture =  useTexture(texturefile)
    //   // const textureMap = useLoader(THREE.TextureLoader, texturefile)
    //   // const textureMap = useLoader(TextureLoader, texturefile)
    //   materials[texture] = materials.glass.clone()
    //   materials[texture].name = texture
    //   // materials[texture].map = textureMap
    // })


    // iterate over all materials and set the color
    Object.values(materials).forEach((material) => {
      //console.log(material.name);

    })

    // iterate over all nodes and set the material    
    Object.values(nodes).forEach((node) => {
        if (node.isMesh) {
          if (topHalf.includes(node.name)) {
            node.material = materials.glassBlue
            node.visible = true
          }
          if (lowerHalf.includes(node.name)) {
            node.material = materials.glassRed
            node.visible = true
          }
          // hide icons
          if (icons.includes(node.name)) {
            if (node.name === 'icon_00') node.material = applyProps(materials.glass.clone(), { map: icon_00, alphaMap: icon_00, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_01') node.material = applyProps(materials.glass.clone(), { map: icon_01, alphaMap: icon_01, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_02') node.material = applyProps(materials.glass.clone(), { map: icon_02, alphaMap: icon_02, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_03') node.material = applyProps(materials.glass.clone(), { map: icon_03, alphaMap: icon_03, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_04') node.material = applyProps(materials.glass.clone(), { map: icon_04, alphaMap: icon_04, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_05') node.material = applyProps(materials.glass.clone(), { map: icon_05, alphaMap: icon_05, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_06') node.material = applyProps(materials.glass.clone(), { map: icon_06, alphaMap: icon_06, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_07') node.material = applyProps(materials.glass.clone(), { map: icon_07, alphaMap: icon_07, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_08') node.material = applyProps(materials.glass.clone(), { map: icon_08, alphaMap: icon_08, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_09') node.material = applyProps(materials.glass.clone(), { map: icon_09, alphaMap: icon_09, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_10') node.material = applyProps(materials.glass.clone(), { map: icon_10, alphaMap: icon_10, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_11') node.material = applyProps(materials.glass.clone(), { map: icon_11, alphaMap: icon_11, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_12') node.material = applyProps(materials.glass.clone(), { map: icon_12, alphaMap: icon_12, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })
            if (node.name === 'icon_13') node.material = applyProps(materials.glass.clone(), { map: icon_13, alphaMap: icon_13, transparent:true, opacity:0.95, polygonOffset:true, polygonOffsetFactor:-.1  })

            // node.onPointerOver = (e) => {
            //   console.log("hovering over icon");
            // }
            // node.onClick = (e) => {
            //   console.log("clicked on icon");
            // }
            // applyProps(node, { onPointerOver: (e) => { console.log("hovering over icon"); } })
            // applyProps(node, { onClick: (e) => { console.log("clicked on icon"); } })
            
            console.log('isMESH:', node.isMesh);
            console.log('GEO:',node.geometry);
            console.log(node.geometry.mesh); // undefined.

            
            // node.mesh
            // adding hover event to node mesh
            // node.onPointerOver = (e) => {
            //   console.log("hovering over icon");
            // }
            // node.onClick = (e) => {
            //   console.log("clicked on icon");
            // }
          }
          // hide cards
          if (cards.includes(node.name)) {
            //console.log("HERE IN CARDS");
            node.visible = false;
          }
          if (node.name === 'ptr_earth') node.material = materials.earth
          // if (node.name==='card') {
          //   console.log("HERE IN CARDS PARENT");
          //   node.visible=false;
          // }
          // console.log(node.name);
          // Fix glas, normals look messed up in the original, most likely deformed meshes bc of compression :/
          //   if (node.name.startsWith('glass')) node.geometry.computeVertexNormals()
          // Fix logo, too dark
          //   if (node.name === 'silver_001_BreakDiscs_0') node.material = applyProps(materials.BreakDiscs.clone(), { color: '#ddd' })
        }
      })
    // applyProps(node, { position: [0, 0, .01]  })
    //   applyProps(materials.black_m, { metalness: 1, color: '#ddd',emissiveIntensity: 0 })
    //   applyProps(scene, { scale: 1.5, position: [0, -1, 0]  })
    //   applyProps(materials.body, { color: soul })

    return <primitive object={scene} {...props} />
}

useGLTF.preload('assets/people/assets/sphere_v6.gltf')
