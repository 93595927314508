import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils';
import React from 'react';
import { useState, useRef } from 'react';
// import { Carousel, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Card, Typography,Box,ButtonGroup} from '@mui/material';
import ReactPlayer from 'react-player';
import Container from "@mui/material/Container"
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import { sizing } from '@mui/system';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {DefaultPlayer as Video} from 'react-html5video';
import { Carousel } from 'react-responsive-carousel';
import ImageModal from "./ImageModal";
import positions from '@mui/system';
import {useEffect} from "react";
// import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import './gallery.css';
import './Ecam.css';
import CloseIcon from '@mui/icons-material/Close';
import JsonData from '../serviceImageGallery.json'

// import headerVideo1 from './assets/slide1.mp4';
// import headerVideo2 from './assets/slide_da2.mp4';
// import headerVideo3 from './assets/slide_tower.mp4';
const EcamPage = () => {
    
    const [activeButton, setActiveButton] = useState(null);
  
    const playerRef = useRef(null);
    const videoRef = useRef(null);

    const handleButtonClick = (button) => {
        setActiveButton(button);
        videoRef.current.play();

    };
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };


        


    return (
        <>
            <div style={{ backgroundImage: 'url(/images/ecamImg/bg1.jpg)', backgroundSize: 'cover' , paddingBottom: '500px'   }}>
                <div style={{position:"relative",top:"450px"}}>

                    <h1>WE BELEIVE IN</h1>
                    <h1> POWERFUL DIGITAL SOLUTIONS </h1>
                    <h2>We make them for ourselves and hope you find them useful</h2>

                </div>
            </div>

            {/* next section */}
  
            <div style={{ backgroundImage: 'url(/images/pipeImg/about_bg.jpg)', backgroundSize: 'cover' , paddingBottom: '100px'   }}>

                <div>
                    <h3 style={{position:"relative",top:"30px",left:"0%",color:"white"}}>ABOUT</h3>
                    <h4 style={{position:"relative",top:"10px",left:"0%",color:"white"}}>Here are key team members </h4>
                </div>
                
                <Grid container spacing={2}>
                    <Grid item xs={4}>

                    <div style={{ position:"relative", width:"50%",height:"60%", left:"30%",top:"0%",marginRight:"10%",paddingTop:"10px"}}>

                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h4" color="common.white">About eCam</Typography>
                    <p >ecam in simple words is B2B hardware device, purchased with subscription. Its meant for construction based vendors. Its sole purpose is to take a highres image after specific interval and sync with cloud lapse video after specific interval on your whats app.
                    eCam is product developed jointly with a Pakistani Company <a href="https://www.evolve.pk/" aria-hidden="true">EVOLVE.</a> Our Team has developed the IOT and software part.</p>
                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h6" color="common.white">MORE DETAILS</Typography>
                    <p>For Pricing & Packages please visit their product page.</p>
                    


                    </div>

                    </Grid>
                    <Grid item xs={8}>

                    <div style={{paddingTop:"40px"}}>
                        <Carousel showThumbs = "false" width = "70%"  >
                            <div>
                                <img src="/images/ecamImg/ec1.jpg"/>
                                {/* <p className="legend">Legend 1</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec2.jpg"/>
                                {/* <p className="legend">Legend 2</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec3.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec4.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec5.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec6.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="/images/ecamImg/ec7.jpg" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                        </Carousel>
                    </div>

                    
                    </Grid>
                </Grid>
                </div>

                {/* next section */}

                <div>
                    <h3 style={{position:"relative",top:"30px",left:"0%",color:"white",fontWeight:"bold"}}>HOW IT WORKS</h3>
                    <p style={{position:"relative",top:"10px",left:"0%",color:"white",textAlign:"center"}}>Simplicity is the glory of expression - Walt Whitman</p>
                </div>


                <Grid container spacing={2}>
                    <Grid item xs={4}>

                    <div style={{ position:"relative", width:"50%",height:"60%", left:"30%",top:"0%",marginRight:"10%",paddingTop:"10px"}}>

                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h5" color="common.white">Core</Typography>
                    <p >It simply takes highres (4K) picture after a minute and saves it on self hosted cloud. At EOB, server then compiles HQ Video and sends it client WhatsApp.</p>
                    <p>Server also take care of client package and send them weekly or monthly time lapses in default package. Our Team has developed the IOT and software part.</p>
                    <Typography sx = {{fontWeight:'bold' }} align = "left" variant="h5" color="common.white">Evolution</Typography>
                    <p>In Future, ecam will be able to live stream HD Videos (on demand)</p>
                    


                    </div>

                    </Grid>
                    <Grid item xs={8}>

                    <div style={{paddingTop:"40px"}}>
                        <img src="/images/ecamImg/whatsapp1.png" style={{width:"60%"}}/>
                    </div>

                    
                    </Grid>
                </Grid>
                {/* next section */}
                <div>
                    <h3 style={{position:"relative",top:"30px",left:"0%",color:"white",fontWeight:"bold"}}>TIMELAPSE IN YOUR WHATSAPP</h3>
                    <p style={{position:"relative",top:"0px",left:"0%",color:"white",textAlign:"center",marginTop:"5px",paddingTop:"30px"}}>Here is our event schedule</p>
                </div>

                <div style={{paddingTop:"40px"}}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{marginLeft:"42%"}} >
                  <Button onClick={() => handleButtonClick('button1')}>Daily</Button>
                  <Button onClick={() => handleButtonClick('button2')}>Weekly</Button>
                  <Button onClick={() => handleButtonClick('button3')} >Monthly</Button>
                </ButtonGroup>
                </div>

                <div>

                <div style={{marginLeft:"20%",marginTop:"30px"}}>
                    {activeButton === 'button1' && (
                        

                    <Video
                        style={{width:'80%'}}
                        autoPlay={true}
                        controls={['PlayPause', 'Seek', 'Time', ]}
                        // poster={src.poster}
                      >
                    <source src="/images/ecamImg/ecam_daily.mp4" type="video/mp4" />
                    </Video>

                        )}

                    {activeButton === 'button2' && (
                    <Video
                        style={{width:'80%'}}
                        autoPlay={true}
                        controls={['PlayPause', 'Seek', 'Time', ]}
                        // poster={src.poster}
                    >
                    <source src="/images/ecamImg/ecam_daily.mp4" type="video/mp4" />
                    </Video>
                       
                        )}

                    {activeButton === 'button3' && (
                    <Video
                        style={{width:'80%'}}
                        autoPlay={true}
                        controls={['PlayPause', 'Seek', 'Time', ]}
                        // poster={each.poster}
                    >
                    <source src="/images/ecamImg/ecam_daily.mp4" type="video/mp4" />
                    </Video>

                        )}
                    </div>
                    {/* <button onClick={handlePauseClick}>Pause</button> */}
                    </div>
                        
                    {/* next section */}
                    <div>
                    <h3 style={{position:"relative",top:"30px",left:"0%",color:"white",fontWeight:"bold"}}>FAQ</h3>
                    


                    <Container maxWidth="sm" sx={{ mt: 20,marginTop:'45px' }}>
                      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0,color: 'black' }}>How can i get this device..?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography sx={{color:'black'}}>
                            Nulla facilisi...
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0,color: 'black' }}>Which tech stack did you use..?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography sx={{color:'black'}}>
                            Donec placerat...
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3bh-content"
                          id="panel3bh-header"
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0,color: 'black' }} >How is this the best choice..?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography sx={{ color: 'black' }}>
                            Nunc vitae ...
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Container>
                    </div>





                

            
    </>
    );

}
export default EcamPage;