
import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_01 from '../experiments/Experiment_01';

export function Experiment_p01() {
    return (
        <>
            <Experiment_01 />
        </>
    );
}
export default Experiment_p01;